import {ResourceApi} from '../../services/api'
import { Colocacion } from './Model'

class ColocacionesApi extends ResourceApi<Colocacion> {

  constructor(){
    super('/api/colocaciones')
  }

}

export default new ColocacionesApi