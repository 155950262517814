import React from 'react'
import Toast from 'react-bootstrap/Toast'

import './BymaToast.scss'

interface ToastProps {
    body: string
    type?: 'error' | 'success'
    header?: string
    className?: string
}

const BymaToast = (props: ToastProps) => {
    const {
        header = undefined,
        body,
        type = 'success',
        className,
    } = props

    const typeClassName = type === 'error' ? 'error-toast ' : 'success-toast '

    return (
            <Toast className={typeClassName + className}>
                {header && <Toast.Header>{header}</Toast.Header>}
                <Toast.Body>{body}</Toast.Body>
            </Toast>
    )
}

export default BymaToast