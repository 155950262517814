import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'

import * as yup from 'yup'
import { Col, Form, Row } from 'react-bootstrap'
import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { BymaButton, TextInput, } from '../../components'

//TODO importar todo este desde ../../components
import BymaCard from '../../components/BymaCard/BymaCard'
import BymaHelpBox from '../../components/BymaHelpBox/BymaHelpBox'
import BymaModal from '../../components/BymaModal/BymaModal'
import BymaPageFrame from '../../components/BymaPageFrame/BymaPageFrame'
import BymaAlert from '../../components/BymaAlert/BymaAlert'
import { authenticationService } from '../../services/auth'
import { useAuthContext } from 'core'

import UsersApi from './UsersApi'

import { useNotificationProducer } from '../../core/notification'
import { useLogout } from '../../core/auth'
import styles from './ChangePassword.module.scss'

interface changePasswordProps {
  expiredPassword?: boolean
}

const schema = () =>
  yup
    .object()
    .shape({
      oldPassword: yup.string()
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .required('campo requerido'),
      newPassword: yup.string()
        .matches(
          /*/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[A-Z]).{8,}$/,*/
          /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[A-Z]).{8,}$/,
          'No cumple con alguna de las especificaciones'
        )
        .min(8, 'La contraseña debe tener al menos 8 caracteres')
        .required('campo requerido'),
      confirmPassword: yup.string()
        .min(8, 'La contraseñna debe tener al menos 8 caracteres')
        .required('campo requerido')
        .oneOf([yup.ref('newPassword')], 'No coinciden las contraseñas')
    }).required()



const ChangePassword = (props: changePasswordProps) => {
  const authContext: AuthContextInfo = useAuthContext()
  const { expiredPassword = false } = props
  const navigate = useNavigate()

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(undefined)

  // Password states
  const [size, setSize] = useState<boolean>(false)
  const [lettersNumbers, setLettersNumbers] = useState<boolean>(false)
  const [oneCapital, setOneCapital] = useState<boolean>(false)
  const [oneNumber, setOneNumber] = useState<boolean>(false)
  const [specialCharacter, setSpecialCharacter] = useState<boolean>(false)

  const methods = useForm<any>({
    resolver: yupResolver(schema()),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  React.useEffect(() => {
    methods.setFocus('codigo')
  }, [methods.setFocus])

  const { addNotification } = useNotificationProducer()

  const logout = useLogout()

  //TODO Usar un nuevo hook useBymaMutation que incluya manejo notification y progress
  const { mutate } = useMutation(
    (user: any) => UsersApi.update({ ...user, id: 2 }),
    {
      onSuccess: data => {
        addNotification({message: '¡Cambio de contraseña exitoso!', type: 'info'})
        if(expiredPassword){
          setTimeout(() => logout(), 2000)
        }
      },
      onError: () => {
        addNotification({ message: '¡No se pudo cambiar la contraseña!', type: 'error' })
      },
      onSettled: () => {
        setTimeout(() => {
          if (expiredPassword) {
            authenticationService.signout({}).then((response) => {
              authContext.setToken(undefined)
              authContext.setAuthenticated(false)
              authContext.setUserInfo(undefined)
            })
            navigate('/login' )
          } 
        }, 3000)
      }
    })

  const onSubmit = (data: any) => {
    setFormData(data)
    setShowConfirm(true)
  }

  const handleConfirm = () => {
    console.log(formData)
    const rol = {
      ...formData
    }
    mutate(formData)
  }

  const handleCancel = () => {
    navigate('/home')
  }

  const validateField = (text: string) => {
    console.log('Validando texto!!!')
    console.log(text)
    // Utiliza 6 caracteres como mínimo.
    setSize(text.length >= 6 ? true : false)
    // Combinación entre números y letras.
    setLettersNumbers(text.match(/^(?=.*[a-zA-Z])(?=.*[0-9])/) ? true : false)
    // Al menos un número.
    setOneNumber(text.match(/\d/) ? true : false)
    // Al menos una letra mayúscula.
    setOneCapital(text.match(/[A-Z]/) ? true : false)
    // Al menos un caracter especial.
    /* eslint-disable */
    setSpecialCharacter(text.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/) ? true : false)
  }

  const renderForm = () => {
    return (
      <FormProvider {...methods}>
        <Form id="change-pass-edit-form"
          onSubmit={methods.handleSubmit(onSubmit)}>
          <BymaCard
            header={"Cambio de contraseña"}
            width='50%'
            footer={
              <div >
                <BymaButton
                  type="submit"
                  id="change-pass-form-btn"
                  name="change-pass-form-btn"
                  label={'Confirmar'}
                  backgroundColor={"#0183D9"}
                  border={"1px solid #0183D9"}
                  onClick={() => { setShowConfirm(true) }}
                  disabled={!methods.formState.isValid}
                />
              </div>
            }>
            <Row>
              <Col>
                <p>Ingresa tu nueva contraseña</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  name="oldPassword"
                  id="oldPassword"
                  label="Antigua contraseña"
                  type="password"
                  //icon="fa-lock"
                  icon="login/lock.svg"
                  placeHolder="Mínimo 8 caracteres"
                  width='95%'
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  name="newPassword"
                  id="newPassword"
                  label="Nueva contraseña"
                  type="password"
                  //icon="fa-lock"
                  icon="login/lock.svg"
                  placeHolder="Mínimo 8 caracteres"
                  width='95%'
                  onChange={(event) => {
                    validateField(event.target.value)
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <TextInput
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirmar nueva contraseña"
                  type="password"
                  //icon="fa-lock"
                  icon="login/lock.svg"
                  placeHolder="Mínimo 8 caracteres"
                  width='95%'
                />
              </Col>
            </Row>
          </BymaCard>

          <BymaModal
            show={showConfirm}
            content={<p>Confirmar el cambio de password?</p>}
            handleCancel={() => { setShowConfirm(false) }}
            handleConfirm={() => {
              handleConfirm()
              setShowConfirm(false)
            }}
          />
        </Form>
      </FormProvider>
    )
  }

  const renderCheckList = () => {
    console.log('rendering checklist')

    return (
      <BymaHelpBox
        title="Seguridad de la contraseña"
        className={styles.changePassHelpBox}
        children={
          <ul className={styles.changePassHelpBoxUl}>
            <li className={size ? styles.checked : styles.notChecked}>
              Utiliza 6 caracteres como mínimo.
            </li>
            <li className={lettersNumbers ? styles.checked : styles.notChecked}>
              Combinación entre números y letras.
            </li>
            <li className={oneNumber ? styles.checked : styles.notChecked}>
              Al menos un número.
            </li>
            <li className={oneCapital ? styles.checked : styles.notChecked}>
              Al menos una letra mayúscula.
            </li>
            <li className={specialCharacter ? styles.checked : styles.notChecked}>
              Al menos un caracter especial.
            </li>
          </ul>
        }
      />
    )
  }

  return (
    <BymaPageFrame
      bcHome={{ value: "Dashboard", link: "/" }}
      bcItems={[]}
      bcActive="Cambio de contraseña"
      title="CAMBIO DE CONTRASEÑA"
      iconTitle='login/lock.svg'
      className={styles.changePassContainer}
      showBreadcrums={!expiredPassword}>

      <Row>
        <Col>
          {expiredPassword &&
            <BymaAlert
              variant="info"
              closable={true}
              height={'40px'}
              children={<p>Tu contraseña está vencida. Crea una nueva para volver a utilizar en el sistema.</p>}
            />
          }
        </Col>
      </Row>
      <Row>
        <Col className="col-4">
          {renderCheckList()}
        </Col>
        <Col className="col-8">
          {renderForm()}
        </Col>
      </Row>
    </BymaPageFrame>
  )
}

export default ChangePassword

