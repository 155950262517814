import React from 'react'
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'
import { Route, Routes } from 'react-router-dom'
import Colocaciones from './features/Colocaciones'
import Agentes from './features/Agentes'
import Roles from './features/Roles'
import Help from './features/Help'
import ChangePassword from 'features/ChangePassword'
import DashboardAgente from 'features/Dashboard'
import BymaHome from 'features/Home'

export default function AppRoutes() {
  return (
    <Routes>

      <Route path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardAgente />
          </ProtectedRoute>
        } />

      <Route path="/home"
        element={
          <ProtectedRoute>
            <BymaHome />
          </ProtectedRoute>
        } />

      <Route path="/colocaciones"
        element={
          <ProtectedRoute>
            <Colocaciones.list />
          </ProtectedRoute>
        } />

      <Route path="/colocaciones/*"
        element={
          <ProtectedRoute>
            <Colocaciones.edit />
          </ProtectedRoute>
        } />

      <Route path="/agentes"
        element={
          <ProtectedRoute>
            <Agentes.list />
          </ProtectedRoute>
        } />

      <Route path="/agentes/*"
        element={
          <ProtectedRoute>
            <Agentes.edit />
          </ProtectedRoute>
        } />

      <Route path="/roles"
        element={
          <ProtectedRoute>
            <Roles.edit />
          </ProtectedRoute>
        } />

      <Route path="/help"
        element={
          <ProtectedRoute>
            <Help.list />
          </ProtectedRoute>
        } />

      <Route path="/change-password"
        element={
          <ProtectedRoute>
            <ChangePassword />
          </ProtectedRoute>
        } />

      <Route path="/change-password-expired"
        element={
            <ChangePassword expiredPassword={true}/>
        } />

      {/*
        <Route path="/roles/:id"
          element={
            <ProtectedRoute>
              <Roles.edit />
            </ProtectedRoute>
          } />
        */}

    </Routes>

  )
}
