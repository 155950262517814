
import React, { useState } from 'react'
import { ThemeConfig } from 'bootstrap-darkmode'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { LinkContainer } from 'react-router-bootstrap'
import BooleanInput from '../../components/Inputs/BooleanInput/BooleanInput'

import { useLogout } from '../../core/auth'
import { withPermissions } from 'core/auth'

import './AppNavbar.scss'

const ROLES_PERMISSIONS = [/rol:*/]
const ADMIN_PERMISSIONS = [...ROLES_PERMISSIONS]

interface NavBarProps {
  empty?: boolean
}


const AppNavBarItems = () => {

 
  const logout = useLogout()

  return (
  <Navbar.Collapse id="responsive-navbar-nav">
  <Nav className="app-navbar-items me-auto my-2 my-lg-0" navbarScroll>

    <NavDropdown
      title="WIP"
      id="admin-scrolling-dropdown"
      className="config-dropdown">

      <LinkContainer to="/dashboard">
        <Nav.Link>Dashboard</Nav.Link>
      </LinkContainer>

    </NavDropdown>

    {withPermissions(

    (<NavDropdown title="Administrar" id="admin-scrolling-dropdown"
      className="config-dropdown">
      
      {
      withPermissions((
        <LinkContainer to="/roles">
          <NavDropdown.Item>Roles</NavDropdown.Item>
        </LinkContainer>
      ), ROLES_PERMISSIONS)
      }

    </NavDropdown>),ADMIN_PERMISSIONS)
    }

  </Nav>


  <Nav className="right-buttons">
    <Nav.Link className="navbar-button" style={{ marginLeft: '4px !important' }}>
      <LinkContainer to="/messages">
        <NavDropdown.Item>
          <img className="navbar-button-icon"
            src="images/icons/navbar/mail.svg" />
        </NavDropdown.Item>
      </LinkContainer>
    </Nav.Link>
    <Nav.Link className="navbar-button">
      <LinkContainer to="/help">
        <NavDropdown.Item>
          <img className="navbar-button-icon"
            src="images/icons/navbar/question-circle.svg" />
        </NavDropdown.Item>
      </LinkContainer>
    </Nav.Link>
    <NavDropdown
      id="config-scrolling-dropdown"
      className="config-dropdown"
      title={
        <img className="navbar-button-icon"
          src="images/icons/navbar/settings.svg" />
      }
    >
      <Nav.Link>
        <BooleanInput
          id="dark-mode-button"
          label="Darkmode"
          value="dark-item"
          onChange={() => /*setDarkTheme(!darkTheme)*/ { }}
          disabled={true} />
      </Nav.Link>
      <NavDropdown.Divider />
      <Nav.Link>
        <LinkContainer to="/change-password">
          <NavDropdown.Item >
            <img className="navbar-dropdown-icon"
              style={{ paddingLeft: '5px' }}
              src="images/icons/login/lock.svg" />
            <span style={{ marginLeft: '4px' }}>Contraseña</span>
          </NavDropdown.Item>
        </LinkContainer>
      </Nav.Link>
      <Nav.Link>
        <LinkContainer to="/login">
          <NavDropdown.Item onClick={logout}>
            <img className="navbar-dropdown-icon"
              src="images/icons/login/logout.svg" />
            <span>Cerrar sesión</span>
          </NavDropdown.Item>
        </LinkContainer>
      </Nav.Link>
      <Nav.Link>
        <LinkContainer to="/">
          <NavDropdown.Item>
            <img className="navbar-dropdown-icon"
              style={{ marginLeft: '5px' }}
              src="images/icons/login/person_filled_grey.svg" />
            <span className="user-label">Nombre usuario</span>
          </NavDropdown.Item>
        </LinkContainer>
      </Nav.Link>
    </NavDropdown>
  </Nav>

</Navbar.Collapse>
)}

const AppNavBar = ({ empty = false} : NavBarProps) => {
  //const navigate = useNavigate()
  const [theme, setTheme] = useState<string>('dark')

  const themeConfig = new ThemeConfig()
  themeConfig.loadTheme = () => { return theme }
  themeConfig.initTheme()

  console.log('DEBUG render AppNavBar')

  return (
    <Navbar bg={theme} expand="lg" className="app-navbar">
      <Container fluid>

        <Navbar.Brand className="navbar-logo" href="/">
          <img
            alt=""
            //src="/images/byma-primarias-color.png"
            src="/images/logos/logo-navbar.svg"
            className="d-inline-block align-top"
          />{' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        {
          !empty ? <AppNavBarItems/> : null
        }


      </Container>
    </Navbar>
  )
}

export default AppNavBar