import React, { ReactNode } from 'react'

import './BymaTitle.scss'

interface TitleProps {
  text: string
  size?: number
  icon?: string
}

const BymaTitle = (props: TitleProps) => {
  const { text, icon = 'icons/widgets.svg' } = props
  return (
    <div className="byma-title">
      <img src={'../images/' + icon}/>
      <p className="title-text">{text}</p>
    </div>
  )
}

export default BymaTitle