import React from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'

import BymaCard from '../../components/BymaCard/BymaCard'
import BymaTitle from '../../components/BymaTitle/BymaTitle'
import BymaHelpBox from '../../components/BymaHelpBox/BymaHelpBox'
import PageHeader from '../../components/PageHeader/PageHeader'
import { BymaButton } from '../../components/Buttons'
import BymaPageFrame from '../../components/BymaPageFrame/BymaPageFrame'
import { HELP_DATA } from './config'


import './Help.scss'

const Help = (props: any) => {

    const renderHelpCards = () => {
        return HELP_DATA.map(item => (
            <div key={'help-item-' + item.order} className='help-item'>
                <BymaCard
                    header={item.title}
                    width='100%'
                    key={'help-item-' + item.order}>
                    <Row>
                        <Col className="col-8">
                            {item.body.map((text: string, idx: number) => (
                                <p key={'text-' + idx}>{text}</p>
                            ))}
                        </Col>
                        <Col className="col-4">
                            {item.button
                                && <BymaButton
                                    id={"button-" + item.order}
                                    name={"button-" + item.order}
                                    label={item.button.text}
                                    icon={item.button.icon}
                                    backgroundColor={'var(--byma-button-gray)'}
                                    border={'1px solid var(--byma-button-gray)'}
                                    className='help-button' />
                            }
                        </Col>
                    </Row>
                </BymaCard>
            </div>
        ))
    }

    return (
        <BymaPageFrame
            //toastBody="hola mundo"
            bcHome={{ value: "Dashboard", link: "/" }}
            bcItems={[]}
            bcActive="Ayuda"
            title="AYUDA"
            iconTitle='navbar/question-circle.svg'
            className="help-container">
            <Row>
                <Col>
                    <BymaHelpBox
                        title="BYMA Primarias"
                        className='change-pass-help-box'
                        children={
                            <span>
                                BYMA Primarias es el Sistema de BYMA para la colocación primaria de valores negociables
                                con oferta pública mediante subasta o licitación pública. Permite que tanto los
                                Agentes Miembros BYMA como otros intermediarios, participar libremente del
                                proceso de colocación primaria.
                            </span>
                        }
                    />
                </Col>
            </Row>
            <Row>
                {renderHelpCards()}
            </Row>

        </BymaPageFrame>
    )
}

export default Help

