import React from 'react'
import Badge from 'react-bootstrap/Badge'

import './BymaBadge.scss'

interface BadgeProps {
    text: string
    type?: 'primary' | 'secondary' | 'danger' | 'warning' | 'info' | 'light' | 'dark' | 'success'
    className?: string
}

const BymaBadge = (props: BadgeProps) => {
    const { text, type = 'success' } = props
    return (
        <Badge pill bg={type}
            className={'byma-badge'}>
            {text}
        </Badge>
    )
}

export default BymaBadge