
import api from './api'

export interface ApiRequest {
    url: string
    params?: object
}

export class ResourceApiMethods<T> {

  getList = async (request: ApiRequest) : Promise<T[]> =>  {
    return api.get(request.url, {params: request.params}).then((response) => response.data as T[])
  }
  
  getOne = async (request: ApiRequest) : Promise<T> => {
    return api.get(request.url).then((response) => response.data as T)
  }
  
  update = async (request: ApiRequest) : Promise<T> => {
    return api.put(request.url, request.params).then((response) => response.data as T)
  }

  export = async (request: ApiRequest) : Promise<Blob> => {
    return api.download(request.url, request.params).then((response) => response.data as Blob)
  }

}

const resolveBackendUrl = (path : string) => {
    return process.env.REACT_APP_BACKEND_URL + path
}

export {resolveBackendUrl}

export class ResourceApi<T extends BaseEntity> {

    apiMethods : ResourceApiMethods<T>
    path: string

    constructor(path: string){
        this.apiMethods = new ResourceApiMethods()
        this.path = path
    }

    getList = async (params?: object) => {
        return this.apiMethods.getList({url: resolveBackendUrl(this.path), params})
    }

    getOne = async (id: any, params?: object) => {
        return this.apiMethods.getOne({url: resolveBackendUrl(`${this.path}/${id}`), params})
    }

    update = async (entity: T, params?: object) => {
        return this.apiMethods.update({url: resolveBackendUrl(`${this.path}/${entity.id}`),params: {...params, ...entity} })
    }

    export = async (params?: object) => {
      return this.apiMethods.export({url: resolveBackendUrl(`${this.path}/export`),params: {...params} })
    }
    
}