import React, { ReactNode } from 'react'
import Modal from 'react-bootstrap/Modal'

import './BymaModal.scss'
import { BymaButton } from '..'

interface ModalProps {
    show: boolean
    content: ReactNode
    title?: string
    width?: string
    height?: string
    // Cancel
    handleCancel: any
    showCancel?: boolean
    cancelText?: string
    // Confirm
    handleConfirm: any
    showConfirm?: boolean
    confirmText?: string
}

const BymaModal = (props: ModalProps) => {
    const {
        show,
        content,
        title = '',
        handleCancel,
        showCancel = true,
        cancelText = 'Cancelar',
        handleConfirm,
        showConfirm = true,
        confirmText = 'Confirmar',
        width = '200px',
        height = 'inherit'
    } = props
    return (
        <Modal show={show}
            onHide={handleCancel}
            className="byma-modal-container"
            /*style={{ width: width, height: height }}*/>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{content}</Modal.Body>
            <Modal.Footer>
                {showCancel && <BymaButton
                    type="button"
                    id="roles-form-btn"
                    name="roles-form-btn"
                    label={cancelText}
                    backgroundColor={"transparent"}
                    border={"1px solid #626573"}
                    onClick={handleCancel}
                />
                }
                {showConfirm && <BymaButton
                    type="submit"
                    id="roles-form-btn"
                    name="roles-form-btn"
                    label={confirmText}
                    backgroundColor={"#0183D9"}
                    border={"1px solid #0183D9"}
                    onClick={handleConfirm}
                />
                }
            </Modal.Footer>
        </Modal>
    )
}

export default BymaModal