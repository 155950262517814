import AxiosMockAdapter from 'axios-mock-adapter-path-params'
import { getApiIdToken } from '../api'

const TOKEN_AGENTE = '5e8891ab188cd28'
const TOKEN_COLOCADOR = '5e8891ab188cd29'
const TOKEN_DEFAULT = '5e8891ab188cd30'
const TOKEN_SW = '5e8891ab188cd31'
const TOKEN_PASSWORD_VENCIDA = '5e8891ab188cd32'
const TOKEN_ADMIN = '5e8891ab188cd33'
const TOKEN_AUDITORIA = '5e8891ab188cd34'
const TOKEN_PASSWORD_WARNING = '5e8891ab188cd35'


const tokens = {
  'agente': TOKEN_AGENTE,
  'colocador': TOKEN_COLOCADOR,
  'sw': TOKEN_SW,
  'admin': TOKEN_ADMIN,
  'auditoria': TOKEN_AUDITORIA,
  'password-vencida': TOKEN_PASSWORD_VENCIDA,
  'password-warning': TOKEN_PASSWORD_WARNING,
  'default': TOKEN_DEFAULT
}

const userInfoMap = {

  [TOKEN_AGENTE]: {
    'authorities': ['ROLE_COLP_AGENTE','colocacion:list:agente'],
    'passwordExpirationDays': 25,
    'organizacion': 'AG.001',
    'username': 'agente'
  },

  [TOKEN_ADMIN]: {
    'authorities': ['ROLE_COLP_ADMIN','rol:list','rol:write'],
    'passwordExpirationDays': 25,
    'organizacion': 'BYMA',
    'username': 'admin'
  },

  [TOKEN_AUDITORIA]: {
    'authorities': ['ROLE_COLP_AUDITORIA','rol:list','colocacion:list:ingresada'],
    'passwordExpirationDays': 25,
    'organizacion': 'AUDITORIA',
    'username': 'auditoria'
  },

  [TOKEN_PASSWORD_VENCIDA]: {
    'authorities': ['ROLE_COLP_AGENTE'],
    'passwordExpirationDays': 0,
    'organizacion': 'AG.001',
    'username': 'agente'
  },

  [TOKEN_PASSWORD_WARNING]: {
    'authorities': ['ROLE_COLP_AGENTE'],
    'passwordExpirationDays': 3,
    'organizacion': 'AG.001',
    'username': 'agente'
  },

  [TOKEN_DEFAULT]: {
    'authorities': ['ROLE_COLP_AGENTE'],
    'passwordExpirationDays': 25,
    'organizacion': 'AG.001',
    'username': 'agente'
  }
}

const authenticate = (data) => {
        const payload = JSON.parse(data)

        if(payload.password && payload.password.startsWith('xxx')){
            return [401, {}]
        }

        let idToken = tokens[payload.username]
        if(!idToken){
            idToken = TOKEN_DEFAULT
        }

        const authenticateResponse = {
           idToken
        }
        return [200, authenticateResponse]
}

const account = () => {

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const idToken = getApiIdToken()!

  let userInfo = userInfoMap[idToken]
  
  if(!userInfo){
    userInfo = userInfoMap[TOKEN_DEFAULT]
  }

  return [200, userInfo ]
}

export const mockAuthentication = (mock: AxiosMockAdapter) => {

  mock.onPost('/api/authenticate').reply(({ data }) => {
    return authenticate(data)
  })

  mock.onGet('/api/account').reply((config: any) => {
    return account()
  })

}

export { authenticate, account }