import { ResourceApi } from "../../services/api/resourceApi";
import { Permiso } from "./Model";

class PermisosApi extends ResourceApi<Permiso> {
  constructor() {
    super("/api/permisos");
  }
}

export default new PermisosApi();
