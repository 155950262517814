import * as React from 'react'
import FilterForm from './FilterForm'
import FilterButton from './FilterButton'

import { Col, Row } from 'react-bootstrap'


import styles from './Filter.module.scss'

const ListFilters = ({ filters }) => {
    return (
        <Row>
            <Col className="col-10">
                <FilterForm filters={filters} className={styles.filtersForm} />
            </Col>
            <Col className="col-2">
                <FilterButton filters={filters} className={styles.filtersButton} />
            </Col>
        </Row>
    )
}

export { ListFilters }