import { useCallback, useMemo, useState, useRef } from 'react'
import lodashDebounce from 'lodash/debounce'

import { useStore } from '../../services/store'
import queryReducer, {
    SET_FILTER,
    HIDE_FILTER,
    SHOW_FILTER,
    SET_PAGE,
    SET_PER_PAGE,
    SET_SORT,
    SORT_ASC,
} from './queryReducer'

import removeEmpty from '../../utils/objects/removeEmpty'
import { useIsMounted } from '../../utils/hooks/hooks'

export interface ListParams {
    sort: string;
    order: string;
    page: number;
    perPage: number;
    filter: any;
    displayedFilters: any;
}

const emptyObject = {}

const defaultSort = {
    field: 'id',
    order: SORT_ASC,
}

const defaultParams = {}

/**
 * Copiado de useListParams .Se remueve el codigo que sincroniza la URL del navegador con los filtros
 * 
 * Check if user has already set custom sort, page, or filters for this list
 *
 * User params come from the store as the params props. By default,
 * this object is:
 *
 * { filter: {}, order: null, page: 1, perPage: null, sort: null }
 *
 * To check if the user has custom params, we must compare the params
 * to these initial values.
 *
 * @param {Object} params
 */
export const hasCustomParams = (params: ListParams) => {
    return (
        params &&
        params.filter &&
        (Object.keys(params.filter).length > 0 ||
            params.order != null ||
            params.page !== 1 ||
            params.perPage != null ||
            params.sort != null)
    )
}


export const getNumberOrDefault = (
    possibleNumber: string | number | undefined,
    defaultValue: number
) => {
    const parsedNumber =
        typeof possibleNumber === 'string'
            ? parseInt(possibleNumber, 10)
            : possibleNumber

    return isNaN(parsedNumber as number) ? defaultValue : parsedNumber
}

/**
 * Merge list params from 3 different sources:
 *   - the query string
 *   - the params stored in the state (from previous navigation)
 *   - the props passed to the List component (including the filter defaultValues)
 */
export const getQuery = ({
    queryFromLocation,
    params,
    filterDefaultValues,
    sort,
    perPage,
}) => {
    const query: Partial<ListParams> =
        Object.keys(queryFromLocation).length > 0
            ? queryFromLocation
            : hasCustomParams(params)
            ? { ...params }
            : { filter: filterDefaultValues || {} }

    if (!query.sort) {
        query.sort = sort.field
        query.order = sort.order
    }
    if (query.perPage == null) {
        query.perPage = perPage
    }
    if (query.page == null) {
        query.page = 1
    }

    return {
        ...query,
        page: getNumberOrDefault(query.page, 1),
        perPage: getNumberOrDefault(query.perPage, 10),
    } as ListParams
}


/**
 * Get the list parameters (page, sort, filters) and modifiers.
 *
 * These parameters are merged from 3 sources:
 *   - the query string from the URL
 *   - the params stored in the state (from previous navigation)
 *   - the options passed to the hook (including the filter defaultValues)
 *
 * @returns {Array} A tuple [parameters, modifiers].
 * Destructure as [
 *    { page, perPage, sort, order, filter, filterValues, displayedFilters, requestSignature },
 *    { setFilters, hideFilter, showFilter, setPage, setPerPage, setSort }
 * ]
 *
 * @example
 *
 * const [listParams, listParamsActions] = useListParams({
 *      resource: 'posts',
 *      location: location // From react-router. Injected to your component by react-admin inside a List
 *      filterDefaultValues: {
 *          published: true
 *      },
 *      sort: {
 *          field: 'published_at',
 *          order: 'DESC'
 *      },
 *      perPage: 25
 * });
 *
 * const {
 *      page,
 *      perPage,
 *      sort,
 *      order,
 *      filter,
 *      filterValues,
 *      displayedFilters,
 *      requestSignature
 * } = listParams;
 *
 * const {
 *      setFilters,
 *      hideFilter,
 *      showFilter,
 *      setPage,
 *      setPerPage,
 *      setSort,
 * } = listParamsActions;
 */
export const useListBackendParams = ({
    debounce = 500,
    filterDefaultValues,
    perPage = 10,
    resource,
    sort = defaultSort,
    storeKey = `${resource}.listParams`,
}: ListParamsOptions): [Parameters, Modifiers] => {

    const [params, setParams] = useStore(storeKey, defaultParams)
    const [localParams, setLocalParams] = useState(params)
    const tempParams = useRef<ListParams>()
    const isMounted = useIsMounted()

    const requestSignature = [
        location.search,
        resource,
        storeKey,
        JSON.stringify(localParams),
        JSON.stringify(filterDefaultValues),
        JSON.stringify(sort),
        perPage,
    ]

    const query = useMemo(
        () =>
            getQuery({
                queryFromLocation: {},
                params: localParams,
                filterDefaultValues,
                sort,
                perPage,
            }),
        requestSignature 
    )

    const changeParams = useCallback(
        action => {
            // do not change params if the component is already unmounted
            // this is necessary because changeParams can be debounced, and therefore
            // executed after the component is unmounted
            if (!isMounted.current) return

            if (!tempParams.current) {
                // no other changeParams action dispatched this tick
                tempParams.current = queryReducer(query, action)

                // schedule side effects for next tick
                setTimeout(() => {
                    //TODO revisar si en este caso hacen falta los localParams

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    setLocalParams(tempParams.current!)
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    setParams(tempParams.current!)

                    console.log(`DEBUG useListBakendParams 1 changeParams localParams = ${JSON.stringify(tempParams.current)}`)    

                    tempParams.current = undefined
                }, 0)
            } else {
                console.log(`DEBUG useListBakcendParams 2.1 changeParams localParams = ${JSON.stringify(tempParams.current)}`)

                // side effects already scheduled, just change the params
                tempParams.current = queryReducer(tempParams.current, action)

                console.log(`DEBUG useListBakcendParams 2.2 changeParams localParams = ${JSON.stringify(tempParams.current)}`)

                setParams(tempParams.current)
            }
        },
        [...requestSignature] 
    )

    const setSort = useCallback(
        (sort: SortPayload) =>
            changeParams({
                type: SET_SORT,
                payload: sort,
            }),
        [changeParams]
    )

    const setPage = useCallback(
        (newPage: number) => changeParams({ type: SET_PAGE, payload: newPage }),
        [changeParams]
    )

    const setPerPage = useCallback(
        (newPerPage: number) =>
            changeParams({ type: SET_PER_PAGE, payload: newPerPage }),
        [changeParams]
    )

    const filterValues = query.filter || emptyObject
    const displayedFilterValues = query.displayedFilters || emptyObject

    const debouncedSetFilters = lodashDebounce((filter, displayedFilters) => {
        changeParams({
            type: SET_FILTER,
            payload: {
                filter: removeEmpty(filter),
                displayedFilters,
            },
        })
    }, debounce)

    const setFilters = useCallback(
        (filter, displayedFilters, debounce = true) =>
            debounce
                ? debouncedSetFilters(filter, displayedFilters)
                : changeParams({
                      type: SET_FILTER,
                      payload: {
                          filter: removeEmpty(filter),
                          displayedFilters,
                      },
                  }),
        [changeParams] 
    )

    const hideFilter = useCallback(
        (filterName: string) => {
            changeParams({
                type: HIDE_FILTER,
                payload: filterName,
            })
        },
        [changeParams]
    )

    const showFilter = useCallback(
        (filterName: string, defaultValue: any) => {
            changeParams({
                type: SHOW_FILTER,
                payload: {
                    filterName,
                    defaultValue,
                },
            })
        },
        [changeParams]
    )

    const parameters_result =  {
        filterValues,
        requestSignature,
        ...query,
    }

    if(!parameters_result.displayedFilters){
        parameters_result.displayedFilters = displayedFilterValues
    }

    return [
        parameters_result,
        {
            changeParams,
            setPage,
            setPerPage,
            setSort,
            setFilters,
            hideFilter,
            showFilter,
        },
    ]
}

export const validQueryParams = [
    'page',
    'perPage',
    'sort',
    'order',
    'filter',
    'displayedFilters',
]


export interface ListParamsOptions {
    debounce?: number;
    // Whether to disable the synchronization of the list parameters with
    // the current location (URL search parameters)
    disableSyncWithLocation?: boolean;
    // default value for a filter when displayed but not yet set
    filterDefaultValues?: FilterPayload;
    perPage?: number;
    resource: string;
    sort?: SortPayload;
    storeKey?: string;
}

interface Parameters extends ListParams {
    filterValues: object;
    displayedFilters: {
        [key: string]: boolean;
    };
    requestSignature: any[];
}

interface Modifiers {
    changeParams: (action: any) => void;
    setPage: (page: number) => void;
    setPerPage: (pageSize: number) => void;
    setSort: (sort: SortPayload) => void;
    setFilters: (filters: any, displayedFilters: any) => void;
    hideFilter: (filterName: string) => void;
    showFilter: (filterName: string, defaultValue: any) => void;
}

