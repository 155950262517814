import { useState } from 'react'


export const useExpandRows = () => {
    // State variable to keep track of all the expanded rows
    // By default, nothing expanded. Hence initialized with empty array.
    const [expandedRows, setExpandedRows] = useState<any[]>([])
  
    // State variable to keep track which row is currently expanded.
    const [expandState, setExpandState] = useState({})
  
    /**
     * This function gets called when show/hide link is clicked.
     */
    const handleExpandRow = (entityId: any) => {
      const currentExpandedRows = expandedRows
      const isRowExpanded = currentExpandedRows.includes(entityId)
  
      const obj = {}
      isRowExpanded ? (obj[entityId] = false) :  (obj[entityId] = true)
      setExpandState(obj)
  
      const newExpandedRows = isRowExpanded ?
            currentExpandedRows.filter(id => id !== entityId) :
            currentExpandedRows.concat(entityId)
  
      setExpandedRows(newExpandedRows)
    }
  
    return { expandedRows , expandState, handleExpandRow }
  }
  