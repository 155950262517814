/* eslint-disable react/display-name */
import React, { Fragment, useState } from 'react'
import { ErrorMessage } from '../../Form/ErrorMessage'
import { useFormContext } from 'react-hook-form'

import styles from './TextInput.module.scss'

interface TextInputProps extends FieldProps {
  icon?: string
  autoComplete?: boolean
  placeHolder?: string
  width?: string
  height?: string
  onChange?: (selected: any) => void;
}


export const TextInput = (props: TextInputProps) => {
  const {
    id,
    name,
    label,
    value,
    className,
    icon,
    onChange: customOnChange,
    type = 'text',
    placeHolder = '',
    width = '90%',
    height = 'inherit',
  } = props

  const { register, formState } = useFormContext()
  const [showPassword, setShowPassword] = useState(false)
  const [currentType, setCurrentType] = useState(type)

  const formFieldProps = register(name)

  const errorMessage: string | undefined = formState.errors[name]?.message as string

  const togglePassword = () => {
    const show = !showPassword
    setCurrentType(show ? 'text' : 'password')
    setShowPassword(show)
  }

  const onChange = (event: any) => {
    formFieldProps.onChange(event)
    if (customOnChange) {
      customOnChange(event)
    }
  }
  
  const inputClasses = (errorMessage ? styles.inputFormFieldError : '') + ' '
    + (icon ? styles.inputLeftIcon : styles.inputNoLeftIcon) + ' '
    + (type == 'password' ? styles.fieldPassword : '')

  return (
    <div
      className={styles.bymaTextField + ' form-group ' + (className ? className : '')}
      style={{ width: width, height: height }}
    >
      {label !== undefined ? <label htmlFor={id}>{label}</label> : null}
      <div className={styles.inputInnerAddon}>
        {
          icon
            ? <img className={styles.inputLeftAddon} src={'images/icons/' + icon} />
            : undefined
        }
        <input
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formFieldProps}
          type={currentType}
          className={styles.inputFormField + ' bg-transparent ' + inputClasses}
          ref={formFieldProps.ref}
          placeholder={placeHolder}
          defaultValue={value}
          autoComplete="new-password"
          onChange={onChange}
        />
        {
          type === 'password' &&
          <img onClick={togglePassword}
            className={styles.inputRightAddon}
            src={'images/icons/login/' + (showPassword ? 'visibility_off.svg' : 'visibility.svg')} />
        }
      </div>

      <ErrorMessage error={errorMessage} />
    </div>
  )
}
