

export const agentesResponse = [
  {
    id: 1,
    name: 'Mario',
    surname: 'Baeza',
    birthDate: '2023-02-02',
    agNumber: 123456789
  },
  {
    id: 1,
    name: 'Pepe',
    surname: 'Lucero',
    birthDate: '2023-02-02',
    agNumber: 123456789
  },
  {
    id: 1,
    name: 'Lucia',
    surname: 'Galan',
    birthDate: '2023-02-02',
    agNumber: 123456789
  },
]

export const getAgenteResponse = (id: number) => {
  const agentes = agentesResponse.filter(col => col.id == id)
  return agentes !== undefined && agentes.length > 0
    ? agentes[0] : {}
}
