import { ResourceApi } from '../../services/api/resourceApi'
import {Rol} from './Model'

class RolesApi extends ResourceApi<Rol> {

  constructor(){
    super('/api/roles')
  }

}

export default new RolesApi

