import { isValidElement, ReactElement, useEffect, useMemo, useState } from 'react'

import { useCheckMinimumRequiredProps } from './checkMinimumRequiredProps'
//import { useListParams } from './useListParams'
import { useListBackendParams } from './useListBackendParams'
import defaultExporter from '../../services/export/defaultExporter'

export const SORT_ASC = 'ASC'
export const SORT_DESC = 'DESC'

export interface ListProps {
    listKey: string;
    serverSideModel?: boolean;

    // the props you can change
    filter?: FilterPayload;
    filters?: ReactElement | ReactElement[];
    filterDefaultValues?: object;
    perPage?: number;
    sort?: SortPayload;
    columnOrder?: ColumnOrderPayload;

    //TODO definir exportador
    //exporter?: Exporter | false;

    // the props managed by react-admin
    basePath?: string;
    debounce?: number;
    hasCreate?: boolean;
    hasEdit?: boolean;
    hasList?: boolean;
    hasShow?: boolean;
    location?: Location;
    path?: string;
    resource?: string;
    // Whether to synchronize the list parameters with the current location (URL search parameters)
    // This is set to true automatically when a List is used inside a Resource component
    syncWithLocation?: boolean;
    [key: string]: any;

    isLoading?: boolean;
    total?:number;
}

export const defaultSort = {
    field: 'id',
    order: SORT_ASC,
}

export interface ListControllerProps<RecordType extends BaseEntity = BaseEntity> {

    serverSideModel: boolean;
    sort: SortPayload;

    //basePath: string;
    //currentSort: SortPayload;
    //data: RecordMap<RecordType>;
    //defaultTitle?: string;

    displayedFilters: any;

    //error?: any;
    
    //exporter?: Exporter | false;

    //filter?: FilterPayload;
    filterValues: any;

    //hasCreate?: boolean;
    hideFilter: (filterName: string) => void;
    //ids: Identifier[];
    
    //loaded: boolean;
    //onSelect: (ids: Identifier[]) => void;
    //onToggleItem: (id: Identifier) => void;
    //onUnselectItems: () => void;
    
    //refetch: Refetch;

    //resource: string;
    //selectedIds: Identifier[];

    setFilters: (
        filters: any,
        displayedFilters: any,
        debounce?: boolean
    ) => void;

    page: number;
    perPage: number;
    setPage: (page: number) => void;
    setPerPage: (page: number) => void;
    total: number;
    hasNextPage: boolean;
    hasPreviousPage: boolean;

    setSort: (sort: SortPayload) => void;
    showFilter: (filterName: string, defaultValue: any) => void;

    setColumnOrder: (columnOrder: ColumnOrderPayload) => void;
    columnOrder: ColumnOrderPayload;

    isLoading: boolean;
    

}

/**
 * Prepare data for the List view
 *
 * @param {Object} props The props passed to the List component.
 *
 * @return {Object} controllerProps Fetched and computed data for the List view
 *
 * @example
 *
 * import { useListController } from 'react-admin';
 * import ListView from './ListView';
 *
 * const MyList = props => {
 *     const controllerProps = useListController(props);
 *     return <ListView {...controllerProps} {...props} />;
 * }
 */
const useListController = <RecordType extends BaseEntity = BaseEntity>(
    props: ListProps
): ListControllerProps<RecordType> => {
    //useCheckMinimumRequiredProps('List', ['basePath'], props);
    useCheckMinimumRequiredProps('List', [], props)

    const {
        basePath,
        exporter = defaultExporter,
        filterDefaultValues,
        hasCreate,
        sort = defaultSort,
        perPage = 10,
        filter,
        debounce = 500,
        syncWithLocation = true,
        resource = '',
        listKey,
        isLoading = false,
        total = 0
        
    } = props

    const [query, queryModifiers] = useListBackendParams({
        resource,
        filterDefaultValues,
        sort,
        perPage,
        debounce,
        disableSyncWithLocation: !syncWithLocation,
        storeKey: `list.${listKey}`
    })

    /*
    useEffect(() => {
        if (
            query.page <= 0 ||
            (!loading && query.page > 1 && ids.length === 0)
        ) {
            // Query for a page that doesn't exist, set page to 1
            queryModifiers.setPage(1);
        } else if (!loading && query.page > totalPages) {
            // Query for a page out of bounds, set page to the last existing page
            // It occurs when deleting the last element of the last page
            queryModifiers.setPage(totalPages);
        }
    }, [loading, query.page, ids, queryModifiers, total, totalPages]);
    */

    const currentSort = useMemo(
        () => ({
            field: query.sort,
            order: query.order,
        }),
        [query.sort, query.order]
    )

    const [columnOrder, setColumnOrder] = useState<ColumnOrderPayload>({columnOrder: undefined})

    return {
        sort: currentSort,
        displayedFilters: query.displayedFilters,
        filterValues: query.filterValues,
        setFilters: queryModifiers.setFilters,
        showFilter: queryModifiers.showFilter,
        hideFilter: queryModifiers.hideFilter,
        setSort: queryModifiers.setSort,
        serverSideModel: props.serverSideModel || true,
        setColumnOrder: (columnOrder) => setColumnOrder(columnOrder),
        columnOrder: columnOrder,

        isLoading,
        page: query.page,
        perPage: query.perPage,
        setPage: queryModifiers.setPage,
        setPerPage: queryModifiers.setPerPage,
        total: total,
        hasNextPage: total != null
            ? query.page * query.perPage < total
            : false,
        hasPreviousPage: query.page > 1,

    }

}


export const injectedProps = [
    'basePath',
    'currentSort',
    'data',
    'defaultTitle',
    'displayedFilters',
    'error',
    'exporter',
    'filterValues',
    'hasCreate',
    'hideFilter',
    'ids',
    'loading',
    'loaded',
    'onSelect',
    'onToggleItem',
    'onUnselectItems',
    'page',
    'perPage',
    'refetch',
    'refresh',
    'resource',
    'selectedIds',
    'setFilters',
    'setPage',
    'setPerPage',
    'setSort',
    'showFilter',
    'total',
    'totalPages',
    'version',
]

/**
 * Select the props injected by the useListController hook
 * to be passed to the List children need
 * This is an implementation of pick()
 */
export const getListControllerProps = props =>
    injectedProps.reduce((acc, key) => ({ ...acc, [key]: props[key] }), {})

/**
 * Select the props not injected by the useListController hook
 * to be used inside the List children to sanitize props injected by List
 * This is an implementation of omit()
 */
export const sanitizeListRestProps = props =>
    Object.keys(props)
        .filter(propName => !injectedProps.includes(propName))
        .reduce((acc, key) => ({ ...acc, [key]: props[key] }), {})

export {useListController}
