import React, { useMemo } from 'react'
import { useQuery } from 'react-query'

import AgentesApi from './AgentesApi'
import { Container } from 'react-bootstrap'

import { BymaTable } from '../../components'
import { BymaNavigateButton } from '../../components/Buttons/BymaNavigateButton'

const AGENTES_COLUMNS = [
  {
    field: 'name',
    comparator: (
      valueA: number,
      valueB: number,
      nodeA: any,
      nodeB: any,
      isDescending: any
    ) => valueA - valueB,
  },
  {
    field: 'surname',
    comparator: (
      valueA: number,
      valueB: number,
      nodeA: any,
      nodeB: any,
      isDescending: any
    ) => valueA - valueB,
  },
  { field: 'model' },
  {
    field: 'birthDate'
  },
  { field: 'id', hide: true, suppressToolPanel: true },
  {
    field: 'edit',
    cellRenderer: BymaNavigateButton,
    cellRendererParams: {
      navigateTo: `/agentes/${1}`,
      label: 'Editar',
      icon: 'fa fa-edit'
    },
  },
]


/**
 *
 * @returns TODO crear componente SbaList
 */
const AgentesList = () => {
  const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), [])
  //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), [])

  const { isLoading, isFetching, isError, data, error } = useQuery(
    'agentes',
    AgentesApi.list,
    { staleTime: 0, refetchOnWindowFocus: false }
  )

  return (

    <BymaTable
      key="agentes"
      rowData={data}
      columns={AGENTES_COLUMNS}
      isLoading={isLoading}
      isFetching={isFetching}
      isError={isError}
      error={error}
    />
  )
}

export default AgentesList
