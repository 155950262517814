import { useNavigate } from 'react-router-dom'
import { authenticationService } from '../../services/auth'
import useAuthContext from './useAuthContext'

export default () => {
    const authContext: AuthContextInfo = useAuthContext()
    const navigate = useNavigate()
    
    return () => {
        authenticationService.signout({}).then(() => {
          navigate('/login')
          authContext.setToken(undefined)
          authContext.setAuthenticated(false)
          authContext.setUserInfo(undefined)
        })
      }
}