import React, { Children, ReactNode } from 'react'

import Dropdown from 'react-bootstrap/Dropdown'

import styles from './BymaDropdownButton.module.scss'

export interface DropdownButtonProps {
    id: string
    name: string
    label?: string
    className?: string
    width?: string
    height?: string
    onClick?: any
    icon?: string
    backgroundColor?: string
    border?: string
    disabled?: boolean
    children: ReactNode
}

export const BymaDropdownButton = (props: DropdownButtonProps) => {
    const {
        id,
        label,
        onClick,
        icon,
        width,
        height,
        className = '',
        backgroundColor = '#0d6efd',
        border = '1px solid #0d6efd',
        disabled = false,
        children
    } = props

    return (
        <Dropdown className={styles.bymaDropdownButton}>
            <Dropdown.Toggle
                variant="primary"
                id="dropdown-basic"
                className={styles.dropdownToggle + ' ' + className}>
                {label ? label : ''}
                {icon
                    ? <img className={styles.buttonLeftAddon} src={'../images/icons/' + icon} />
                    : undefined}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    )
}