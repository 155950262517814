import AxiosMockAdapter from 'axios-mock-adapter-path-params'

import { mockColocaciones } from './colocacionFixtures'
import { mockRoles } from './rolFixtures'
import { mockPermisos } from './permisoFixtures'
import {mockAuthentication } from './loginFixtures'

import { agentesResponse, getAgenteResponse } from './agenteFixtures'


export const initMock = (mock: AxiosMockAdapter) => {
  /* Auth */
  mockAuthentication(mock)

  /* Colocaciones */
  mockColocaciones(mock)

  /* Agentes */

  mock.onGet('/api/agentes').reply(() => {
    return [200, agentesResponse]
  })

  mock.onGet('/api/agentes/{id}').reply(() => {
    return [200, getAgenteResponse(1)]
  })

  /* Roles */
  mockRoles(mock)

  /* Permisos */
  mockPermisos(mock)

  mock.onPut('/api/users/:id').reply((config: any) => {
    const { id } = config.routeParams
    console.log(`MOCK PUT /api/users/${id}`)
    return [200, { data: {} }]
  })

  /* Usuarios */

  mock.onPut('/api/users/:id').reply((config: any) => {
    const { id } = config.routeParams
    const { oldPassword } = config
    console.log(`MOCK PUT /api/users/${id}`)
    console.log(JSON.parse(config.data))
    const data = JSON.parse(config.data)
    if (data && data.oldPassword && data.oldPassword.startsWith('xxx')) {
      return [500, { data: {} }]
    }

    return [200, { data: {} }]
  })
};
