import React from 'react'
import { useQuery } from 'react-query'

import ColocacionDashboardApi from './ColocacionDashboardApi'
import ColocacionDashboardTable from './ColocacionDashboardTable'

import {
  ListFilters,
  TextInput,
  PaginationControl,
  Loading
} from '../../../components'

import {
  ListBase,
  useListContext
} from '../../../core'


//
//TODO implementar un HOC withListQuery que inyecte las propiedades {data,isLoading,...etc} en el componente
//
const ColocacionesDashboardList = () => {

  const { filterValues, sort } = useListContext()

  const getQueryKey = (resource, params) => {
    return `resource?${JSON.stringify(params)}`
  }

  const queryParams = { filter: filterValues, sort }

  const { isLoading, isFetching, isError, data, error } = useQuery(

    getQueryKey('colocaciones', queryParams),

    () => ColocacionDashboardApi.getList(queryParams),

    {
      staleTime: 0,
      refetchOnWindowFocus: false,
      //keeppreiousdata para evitar que se vacie la tabla mientras actualiza
      keepPreviousData: true
    }
  )

  return (
    <Loading
      isLoading={isLoading || isFetching}
      loadingText="Cargando colocaciones..."
    >
      {
        data ? <ColocacionDashboardTable data={data} /> : null
      }
    </Loading>
  )

}

const ColocacionDashboardPanel = () => {
  const filters = [
    <TextInput id="id" name="id" key="id" label="Busqueda" />,
    <TextInput id="denominacion" name="denominacion" key="denominacion" label="Denominacion" />,
    <TextInput id="emisor" name="emisor" key="emisor" label="Emisor" />,
    <TextInput id="estado" name="estado" key="estado" label="Estado" />,
    <TextInput id="fechaDesde" name="fechaDesde" key="fechaDesde" label="Fecha desde" />,
    <TextInput id="fechaHasta" name="fechaHasta" key="fechaHasta" label="Fecha hasta" />,
  ]

  return (
    <ListBase listKey="dashboard_agente.colocaciones">
      <ListFilters filters={filters} />
      <ColocacionesDashboardList />
      <PaginationControl total={50} limit={10} />
    </ListBase>
  )
}


export default ColocacionDashboardPanel
