import React, { useEffect } from 'react'

import BymaToast from './BymaToast'

import { useNotificationConsumer, useNotificationProducer } from '../../core/notification'

const BymaTransientToast = ( {timeout = 5000} ) => {

    const { removeNotifications } = useNotificationProducer()
    const { notifications } = useNotificationConsumer()

    useEffect(() => {
        //TODO JIRA Optimizar eliminando el interval una vez que se eliminan todas las notificaciones
        const interval = setInterval(() => {
            removeNotifications(timeout)

        }, timeout)
        return () => {clearInterval(interval)}
    }, [removeNotifications])

    return (
        <>
            {
                notifications.length > 0 ?
                    (
                        <BymaToast 
                            key={notifications[notifications.length - 1].message}
                            body={notifications[notifications.length - 1].message} 
                            type={notifications[notifications.length - 1].type === 'error' ? 'error' : 'success'}/>
                    )
                    :
                    null
            }
        </>
    )
}

export default BymaTransientToast