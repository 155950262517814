import { axiosInstance } from './axiosInstance'

//import Cookies from 'js-cookie'
import { getApiIdToken } from './tokenHolder'

const getRequestHeaders = (url: string) => {
  const idToken = getApiIdToken()
  return idToken ? { 'authorization': `Bearer ${idToken}`} : {}
}

export default {
  get: <T>(url: string, params?: object) =>
    axiosInstance.get<T>(url, {
      headers: getRequestHeaders(url),
      ...params,
    }),
  post: <T>(url: string, data: any) =>
    axiosInstance.post<T>(url, data, {
      headers: getRequestHeaders(url),
    }),
  put: <T>(url: string, data: any) =>
    axiosInstance.put<T>(url, data, {
      headers: getRequestHeaders(url),
    }),
  patch: <T>(url: string, data: any) =>
    axiosInstance.patch<T>(url, data, {
      headers: getRequestHeaders(url),
    }),
  delete: <T>(url: string) =>
    axiosInstance.delete<T>(url, {
      headers: getRequestHeaders(url),
    }),
  download: (url: string, params?: object) => 
    axiosInstance.get(url, {
      headers: { ...getRequestHeaders(url) ,  'Accept': 'application/octet-stream'} ,
      responseType: 'blob',
  }),
 
}



