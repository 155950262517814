import * as React from 'react'

import { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'
//import MenuItem from '@material-ui/core/MenuItem';
//import { FieldTitle, useResourceContext } from 'ra-core';

import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form'

export const FilterButtonMenuItem = forwardRef<any, FilterButtonMenuItemProps>(
    (props, ref) => {

        const { filter, onShow, onHide, checked } = props

        const onChange = useCallback((event) => {
            if(event.target.checked){
                onShow({
                    source: filter.props.name,
                    defaultValue: filter.props.defaultValue,
                })
            }else{
                onHide({
                    source: filter.props.name,
                })
            }
 
        }, [filter.props.source, onShow, onHide])

        console.log(`DEBUG FilterButtonMenuItem render name=${filter.props.name} checked=${checked}`)

        return (

            /*
            <Dropdown.Item
                onClick={handleShow}
                ref={ref}
            >
                {filter.props.name}
            </Dropdown.Item>
            */

            //see https://react-bootstrap.github.io/forms/checks-radios/
            <Form.Check
                type='checkbox'
                name={filter.props.name}
                label={filter.props.label || filter.props.name}
                value={filter.props.name}
                key={filter.props.name}
                checked={checked}
                onChange={onChange}
             />

             //checked={paymentMethod === "PayPal"}
             //onChange={onPaymentMethodChange}

             //                    type="switch"
            //    defaultChecked={props?.searchIn?.title}
            //    onChange={props?.handleSearchIn} />

            /*
            <MenuItem
                className="new-filter-item"
                data-key={filter.props.source}
                data-default-value={filter.props.defaultValue}
                key={filter.props.source}
                onClick={handleShow}
                ref={ref}
            >
                <FieldTitle
                    label={filter.props.label}
                    source={filter.props.source}
                    resource={resource}
                />
            </MenuItem>
            */
        )
    }
)

FilterButtonMenuItem.propTypes = {
    filter: PropTypes.element.isRequired,
    onShow: PropTypes.func.isRequired,
    //resource: PropTypes.string,
}

export interface FilterButtonMenuItemProps {
    filter: JSX.Element;
    onShow: (params: { source: string; defaultValue: any }) => void;
    onHide: (params: { source: string }) => void;
    checked: boolean;
    resource: string;
}
