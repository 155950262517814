import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'

import { useExpandRows } from './useExpandRow'
import BymaAccordion from '../../../components/BymaAccordion/BymaAccordion'
import BymaBadge from '../../../components/BymaBadge/BymaBadge'
import BymaCard from '../../../components/BymaCard/BymaCard'
import { BymaButton } from '../../../components/Buttons'

import styles from './ColocacionDashboardTable.module.scss'

const tdClassNames = (...args: string[]) => {
  const names = args.map(n => styles[n]).join(' ')
  return names.length > 0 ? `${styles.tdCommon} ${names}` : styles.tdCommon
}

const SpacerRow = () => (<tr className={styles.trSpacer}></tr>)

/**
 * Detalle del Titulo (Ultimo Nivel)
 * @see https://www.figma.com/file/c2qUdTS8jUMRlCLf6x23MA/Dashboard?node-id=2820-103995&t=kC8hLdW7kTMA0Aw9-0
 * 
 * @param param0 
 * @returns 
 */
const TituloColocacionDetail = ({ colocacion, titulo, last }) => {

  const tdClass = last ? tdClassNames('singleTdLastRow') : tdClassNames()

  return (
    <>
      <tr>

        <td colSpan={6} className={tdClass}>
          <div>
            {`VN Emision: ${titulo.totalEmision}`}
          </div>
          <div>
            {'Tramo Competitivo'}
          </div>
          <div>
            {`Cantidad Minima: ${titulo?.tramoCompetitivo?.cantidadMinima}`}
          </div>

        </td>
      </tr>
      {
        last ? <SpacerRow /> : null
      }
    </>
  )
};

/**
 * Filas para desplegar los titulos de una colocacion (Nivel Intermedio)
 * 
 * @see https://www.figma.com/file/c2qUdTS8jUMRlCLf6x23MA/Dashboard?node-id=2819-82999&t=kC8hLdW7kTMA0Aw9-0
 * 
 * @param param0
 * @returns
 */
const TitulosColocacionRows = ({ colocacion }) => {
  const { expandedRows, handleExpandRow } = useExpandRows()

  const count = colocacion.titulos.length

  return (
    <>
      {
        colocacion.titulos.map((titulo, index) => {

          const expanded = expandedRows.includes(titulo.id)
          const last = index == count - 1

          const firstTdClass = last && !expanded ? tdClassNames('firstTdLastRow') : tdClassNames()
          const lastTdClass = last && !expanded ? tdClassNames('lastTdLastRow') : tdClassNames()
          const defaultTdClass = tdClassNames()

          return (
            <>
              <tr key={titulo.id}>

                <td className={firstTdClass}>
                  <Button
                    variant="link"
                    onClick={(event) => handleExpandRow(titulo.id)}
                  >
                    {expanded ? '-' : '+'}
                  </Button>
                </td>

                <td colSpan={2} style={{ textAlign: 'left' }} className={defaultTdClass}>
                  {titulo.denominacion}
                </td>

                <td className={lastTdClass}>
                  <Button>Ofertar</Button>
                </td>

              </tr>
              <>
                {
                  expanded ?
                    (
                      <TituloColocacionDetail
                        colocacion={colocacion}
                        titulo={titulo}
                        last={last}
                      />
                    )
                    : last ?
                      <SpacerRow />
                      : null
                }
              </>
            </>
          )
        })}
    </>
  )
}

/**
 * Fila para desplegar una Colocacion (Primer Nivel)
 * @param param0 
 * @returns 
 */
const ColocacionRow = ({ colocacion, handleExpandRow, expanded }) => {

  const firstTdClass = expanded ? tdClassNames('firstTdFirstRow') : tdClassNames('firstTdSingleRow')
  const lastTdClass = expanded ? tdClassNames('lastTdFirstRow') : tdClassNames('lastTdSingleRow')
  const defaultTdClass = tdClassNames()

  return (
    <>
      <tr key={colocacion.id}>

        {/* primera columna */}
        <td className={firstTdClass} colSpan={2}>
          {colocacion['denominacion']}
        </td>

        <td className={defaultTdClass}>
          {colocacion['estado']}
        </td>

        {/* ultima columna */}
        <td className={lastTdClass}>
          <Button
            variant="link"
            onClick={(event) => handleExpandRow(colocacion.id)}
          >
            {expanded ? '-' : '+'}
          </Button>
        </td>
      </tr>
      <>
        {
          expanded ? (
            <TitulosColocacionRows colocacion={colocacion} />
          ) : <SpacerRow />
        }
      </>
    </>
  )
}


/**
 * Tabla para desplegar todas las colocaciones
 * @see https://www.figma.com/file/c2qUdTS8jUMRlCLf6x23MA/Dashboard?node-id=2819-82957&t=kC8hLdW7kTMA0Aw9-0
 * 
 * @param param0 
 * @returns 
 */
const ColocacionDashboardTable = ({ data }) => {
  const { expandedRows, handleExpandRow } = useExpandRows()

  console.log('ACORDEON!!!!')
  console.log(data)

  const getAccordionHeader = (colocacion) => {
    return <Row style={{ width: '100%' }}>
      <Col className="col-6">{colocacion.denominacion}</Col>
      <Col className="col-3"><BymaBadge text={colocacion.estado} /></Col>
    </Row>
  }

  const getTituloHeader = (titulo) => {
    return <Row style={{ width: '100%' }}>
      <Col className="col-6">{titulo.denominacion}</Col>
      <Col className="col-3">
        <BymaButton
          id={'button-titulo-' + titulo.id}
          name={'button-titulo-' + titulo.id}
          label={'Ofertar'} />
      </Col>
    </Row>
  }

  const getTituloBody = (titulo) => {
    return <div>
      <p>{'VN Emision: ' + titulo.totalEmision}</p>

      <Row>
        <Col>
          <BymaCard header="Tramo Competitivo">
            <Row>
              <Col>{'Cantidad Mínima: '}</Col>
              <Col>{'Cantidad Máxima: '}</Col>
            </Row>
            <Row>
              <Col>{'Precio Mínimo: '}</Col>
              <Col>{'Precio Máximo: '}</Col>
            </Row>
            <Row>
              <Col>{'Multiplo oferta: '}</Col>
              <Col></Col>
            </Row>
          </BymaCard>
        </Col>
        <Col>
          <BymaCard header="Tramo No Competitivo">
            <Row>
              <Col>{'Cantidad Mínima: '}</Col>
              <Col>{'Cantidad Máxima: '}</Col>
            </Row>
            <Row>
              <Col>{'Multiplo oferta: '}</Col>
              <Col></Col>
            </Row>
          </BymaCard>
        </Col>
      </Row>
    </div>
  }

  const getAccordionBody = (colocacion) => {
    //return <TitulosColocacionRows colocacion={item} />
    return <BymaAccordion
      key={'titulos-accordion-' + colocacion.id}
      items={
        colocacion.titulos.map(titulo => (
          {
            header: getTituloHeader(titulo),
            body: getTituloBody(titulo)
          }
        ))
      }
    />
  }


  return (
    <Container>
      <Row>
        <Col sm={12}>
          {
            <BymaAccordion
              key={'main-colocacion-accordion'}
              items={
                data.map(colocacion => (
                  {
                    header: getAccordionHeader(colocacion),
                    body: getAccordionBody(colocacion)
                  }
                ))
              }
            />
            }

          {/*
          <div>
            <Table responsive variant="dark" className={styles.table}>
              <colgroup>
                <col span={1} style={{ width: '10%' }} />
                <col span={1} style={{ width: '50%' }} />
                <col span={1} style={{ width: '30%' }} />
                <col span={1} style={{ width: '10%' }} />
              </colgroup>

              <tbody>
                {
                  data.map((colocacion) =>
                  (
                    <ColocacionRow
                      key={colocacion.id}
                      colocacion={colocacion}
                      handleExpandRow={handleExpandRow}
                      expanded={expandedRows.includes(colocacion.id)} />
                  ))}
              </tbody>
            </Table>
          </div>
          */}



        </Col>
      </Row>
    </Container>
  )
}

export default ColocacionDashboardTable
