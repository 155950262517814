import React, { useEffect, useState, ReactNode } from 'react'
import Alert from 'react-bootstrap/Alert'

import './BymaAlert.scss'

interface AlertProps {
    children: ReactNode
    onClose?: any;
    variant?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
    closable?: boolean
    width?: string
    height?: string
}

const BymaAlert = (props: AlertProps) => {
    const {
        children,
        onClose = () => {},
        variant = 'info',
        closable = false,
        width = '100%',
        height = 'inherit'
    } = props

    const [visible, setVisible] = useState<boolean>(true)

    const handleVisible = () => {
        setVisible(true)
        setTimeout(() => {
            setVisible(false)
        }, 5000)
    }

    useEffect(() => {
        console.log('Handle visible!!!!')
        console.log(visible)
        //handleVisible()
    }, [])

    return (
        <Alert
            className='byma-alert'
            key={'alert-' + variant}
            variant={variant}
            onClose={() => {
                onClose()
                setVisible(false)
            }}
            show={visible}
            style={{ width: width, height: height }}
            dismissible>
            {children}
        </Alert>
    )
}

export default BymaAlert