
import React, { ReactNode } from 'react'
import { Col, Container, Row } from 'react-bootstrap'

import BymaBreadcrumb from '../BymaBreadcrumb'
import { BymaTransientToast } from '../BymaToast'
import BymaTitle from '../BymaTitle'

import './BymaPageFrame.scss'

interface PageFrameProps {
  children: ReactNode
  bcHome: BCLink
  bcItems: BCLink[]
  bcActive: string
  title: string
  iconTitle: string
  className?: string
  showBreadcrums?: boolean
  showTitle?: boolean
}

//TODO JIRA BP-39 , BP41 : Utilizar useLoading y useNotification para indicador de progreso y notificaciones respectivamente
const BymaPageFrame = (props: PageFrameProps) => {
  const {
    bcHome,
    bcItems,
    bcActive,
    children,
    title,
    iconTitle,
    className = '',
    showBreadcrums = true,
    showTitle = true
  } = props

  return (
    <React.Fragment>
      <Container className={'byma-page-frame ' + className}>
        <Row className='byma-page-breadcrumb'>
          <Col className="col-8">
            {showBreadcrums &&
              <BymaBreadcrumb
                home={bcHome}
                items={bcItems}
                active={bcActive}
              />}
          </Col>
          <Col className="col-4">
             <BymaTransientToast/>
          </Col>
        </Row>
        <Row className='byma-page-title'>
          {showTitle &&
            <BymaTitle text={title} icon={'icons/' + iconTitle} />}
        </Row>

        <Row className='byma-page-body'>
          {children}
        </Row>
      </Container>

    </React.Fragment>
  )
}

export default BymaPageFrame