import React, { ReactNode } from 'react'
import LoadingOverlay from 'react-loading-overlay'

import './Loading.scss'

interface LoadingProps {
  isLoading: boolean
  loadingText?: string
  children: ReactNode
}

const Loading = (props: LoadingProps) => {
  return (
    <LoadingOverlay
      active={props.isLoading}
      text={props.loadingText}
      className={"byma-loading"}
      spinner>
      {props.children}
    </LoadingOverlay>
  )
}

export default Loading
