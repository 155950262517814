import React, { ReactNode } from 'react'
import Card from 'react-bootstrap/Card'

import './BymaCard.scss'

interface CardProps {
    children: ReactNode
    header?: string
    footer?: ReactNode
    width?: string
    height?: string
    className?: string
}

const BymaCard = (props: CardProps) => {
    const { children, header, footer, className = '', height = 'inherit', width = '100%' } = props
    return (
        <div className="byma-card-container"
            style={{ width: width, height: height }}>
            {header && <Card.Header className='byma-card-header'>{header}</Card.Header>}
            <Card className={"byma-card " + className}>
                <Card.Body className='byma-card-body'>
                    {children}
                </Card.Body>
                {footer && <Card.Footer className="byma-card-footer">{footer}</Card.Footer>}
            </Card>
        </div>
    )
}

export default BymaCard