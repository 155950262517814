import axios, { AxiosInstance } from 'axios'
import AxiosMockAdapter from 'axios-mock-adapter-path-params'

import { initMock } from '../mock'

interface AxiosImpl {
  axiosInstance: AxiosInstance;
  axiosMockAdapterInstance: AxiosMockAdapter;
}

const applyMiddleware = (axiosInstance: AxiosInstance) => {

  axiosInstance.interceptors.request.use(request => {
    console.log(`DEBUG API-REQUEST ${request.method} ${request.url}`, JSON.stringify(request, null, 2))
    return request
  })
  
  axiosInstance.interceptors.response.use(response => {
    console.log(`DEBUG API-RESPONSE ${response.config.url} ${response.config.method}`, JSON.stringify(response, null, 2))
    return response
  })

  return axiosInstance
}

const createAxiosMock = (): AxiosImpl => {
  console.log('ATENCION: Utilizando Axios MOCK')

  const routeParams = {
    ':id': '[0-9]{1,8}',
  }

  const axiosMockInstance = axios.create()
  const axiosMockAdapterInstance = new AxiosMockAdapter(axiosMockInstance, {
      delayResponse: 500,
    },
    routeParams
  )

  initMock(axiosMockAdapterInstance)
  return { axiosInstance: applyMiddleware(axiosMockInstance), axiosMockAdapterInstance }
}

const createAxiosLive = () => {
  const axiosLiveInstance = axios.create()
  return { axiosInstance: applyMiddleware(axiosLiveInstance), axiosMockAdapterInstance: null }
}


export const { axiosInstance, axiosMockAdapterInstance } = process.env
  .REACT_APP_isAxiosMock === 'true'
  ? createAxiosMock()
  : createAxiosLive()
