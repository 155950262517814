import React from 'react'
import { useQuery } from 'react-query'

import ColocacionesApi from './ColocacionesApi'
import { 
  ListFilters,
  TextInput,
  BymaTable
} from '../../components'
import { BymaNavigateButton } from '../../components/Buttons/BymaNavigateButton'

import './Colocaciones.scss'
import { ListBase, useListContext } from '../../core'

const COLOCACIONES_COLUMNS = [
  {
    field: 'id',
  },
  { field: 'fechaAlta' },
  {
    field: 'colocadorDenominacion',
    headerName: 'Colocador'
  },
  {
    field: 'emisorDenominacion',
    headerName: 'Emisor'
  },
  {
    field: 'totalEmision',
  },
  {
    field: 'edit',
    cellRenderer: BymaNavigateButton,
    cellRendererParams: {
      navigateTo: `/colocaciones/${1}`,
      label: 'Editar',
      //icon: 'fa fa-edit'
      icon: 'edit-square.svg'
    },
  },
]

//
//TODO implementar un HOC withListQuery que inyecte las propiedades {data,isLoading,...etc} en el componente
//
const ColocacionesTable = () => {

  const {filterValues, sort} =  useListContext()

  const getQueryKey = (resource, params) => {
    return `resource?${JSON.stringify(params)}`
  }

  const queryParams = { filter: filterValues, sort }

  const { isLoading, isFetching, isError, data, error } = useQuery(

    getQueryKey('colocaciones', queryParams),

    () => ColocacionesApi.getList(queryParams),
    
    { 
      staleTime: 0, 
      refetchOnWindowFocus: false , 
      //keeppreiousdata para evitar que se vacie la tabla mientras actualiza
      keepPreviousData: true
    }
  )

  return (
      <BymaTable
        key="colocaciones"
        rowData={data}
        columns={COLOCACIONES_COLUMNS}
        isLoading={isLoading}
        isFetching={isFetching}
        isError={isError}
        error={error}
      />
  )

}

const ColocacionesList = () => {
  const filters = [
    <TextInput id="id" name="id" key="id"/>,
    <TextInput id="fecha" name="fecha" key="fecha"/>,
  ]

  return (
    <ListBase listKey="colocaciones.hist">
      <ListFilters filters={filters}></ListFilters>
      <ColocacionesTable/>
    </ListBase>
  )
}


export default ColocacionesList
