interface HelpButton {
  icon: string
  text: string
  link: string
}

interface HelpItem {
  order: number
  title: string
  body: string[]
  button?: HelpButton
}

export const HELP_DATA: HelpItem[] = [
  {
    order: 1,
    title: "Manual de usuario",
    body: [
      "Conocé más sobre nuestra aplicación. ",
      "Podrás encontrar todas las funcionalidades detalladas y los diferentes usos de la plataforma, en el Manual del usuario de BYMA Primarias.",
    ],
    button: {
      text: "Descargar Manual de usuario",
      icon: "download.svg",
      link: ""
    }
  },
  {
    order: 2,
    title: "Video tutorial",
    body: [
      "Aprendé a manejar nuestra plataforma de la forma más sencilla.",
      "Accedé a una demo grabada con definiciones de las principales funcionalidades y ejemplos de uso de la aplicación BYMA Primarias.",
    ],
    button: {
      text: "ir al video",
      icon: "send.svg",
      link: ""
    }
  },
  {
    order: 3,
    title: "Colocaciones",
    body: [
      "Accedé a la información, documentación y listado de las colocaciones habilitadas en BYMA Primarias.",
    ],
    button: {
      text: "Ir a colocaciones",
      icon: "send.svg",
      link: ""
    }
  },
  {
    order: 4,
    title: "Aranceles",
    body: [
      "Conocé nuestros aranceles.",
      "Podrás encontrar nuestros derechos de mercado y membresías publicados en nuestra web institucional.",
    ],
    button: {
      text: "Derechos y membresías",
      icon: "send.svg",
      link: ""
    }
  },
  {
    order: 5,
    title: "Normativa legal",
    body: [
      "Consultá el Comunicado N° 14306.",
      "Expone las disposiciones que regulan el mercado primario en BYMA.",
    ],
    button: {
      text: "Comunicado N° 14306",
      icon: "send.svg",
      link: ""
    }
  },
  {
    order: 6,
    title: "Contacto",
    body: [
      "Consultas y soporte sobre la plataforma BYMA Primarias:",
      "Centro de atención BYMA: atencion@byma.com.ar  +54 9 11 4316 6000",
      "Mercado Primario BYMA: byma.primarias@byma.com.arv",
    ]
  },
];
