import * as React from 'react'
import { useState, useRef, useEffect, useCallback } from 'react'
import isEqual from 'lodash/isEqual'

// thanks Kent C Dodds for the following helpers

export function useSafeSetState<T>(
  initialState?: T | (() => T)
): [T | undefined, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setState] = useState(initialState)

  const mountedRef = useRef(false)
  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])
  const safeSetState = useCallback(
    (args) => {
      if (mountedRef.current) {
        return setState(args)
      }
    },
    [mountedRef, setState]
  )

  return [state, safeSetState]
}

export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export function useDeepCompareEffect(callback, inputs) {
  const cleanupRef = useRef()
  useEffect(() => {
    if (!isEqual(previousInputs, inputs)) {
      cleanupRef.current = callback()
    }
    return cleanupRef.current
  })
  const previousInputs = usePrevious(inputs)
}

export function useTimeout(ms = 0) {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, ms)

    return () => {
      clearTimeout(timer)
    }
  }, [ms])

  return ready
}

export function useIsMounted() {
  const isMounted = useRef(true)
  useEffect(() => {
      isMounted.current = true
      return () => {
          isMounted.current = false
      }
  }, [])
  return isMounted
}

// allow the hook to work in SSR
const useLayoutEffect =
    typeof window !== 'undefined' ? React.useLayoutEffect : React.useEffect

/**
 * Alternative to useCallback that doesn't update the callback when dependencies change
 *
 * @see https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback
 * @see https://github.com/facebook/react/issues/14099#issuecomment-440013892
 */
export const useEvent = <Args extends unknown[], Return>(
    fn: (...args: Args) => Return
): ((...args: Args) => Return) => {
    const ref = React.useRef<(...args: Args) => Return>(() => {
        throw new Error('Cannot call an event handler while rendering.')
    })

    useLayoutEffect(() => {
        ref.current = fn
    })

    return useCallback((...args: Args) => ref.current(...args), [])
}
