import {ResourceApi} from '../../../services/api'
import { ColocacionDashboard } from './Model'

class ColocacionDashboardApi extends ResourceApi<ColocacionDashboard> {

  constructor(){
    super('/api/colocaciones/dashboard')
  }

}

export default new ColocacionDashboardApi