import api from '../../services/api/api'

interface ApiRequest {
  url: string
  params?: object
}

const getList = (request: ApiRequest) => {
  return api.get(request.url).then((response) => response.data as Agente[])
}

const getOne = (request: ApiRequest) => {
  console.log('Buscando agente ' + request.url)
  return api.get(request.url).then((response) => response.data as Agente)
}

const update = (request: ApiRequest) => {
  return api.get(request.url).then((response) => response.data as Agente[])
}

export default {
  list: () => getList({ url: '/api/agentes' }),
  getOne: (id: number) => getOne({ url: '/api/agentes/{id}' }),
}
