import React from 'react'
import { Button } from 'react-bootstrap'

import styles from './BymaButton.module.scss'

export interface ButtonProps {
    id: string
    name: string
    label: string
    type?: 'submit' | 'button' | 'reset' | undefined
    className?: string
    groupClassName?: string
    width?: string
    height?: string
    onClick?: any
    icon?: string
    backgroundColor?: string
    disabledColor?: string
    border?: string
    disabled?: boolean
}

export const BymaButton = (props: ButtonProps) => {
    const {
        id,
        label,
        onClick,
        icon,
        width,
        height,
        className = '',
        groupClassName = '',
        type = 'submit',
        backgroundColor = '#0d6efd',
        border = '1px solid #0d6efd',
        disabledColor = '#626573',
        disabled = false
    } = props
  
    const buttonClasses = styles.bymaButton + ' '
      + (icon ? styles.buttonLeftIcon : styles.buttonNoLeftIcon) + ' '
      + className

    return (
        <div className={styles.buttonGroup + ' ' + groupClassName}>
            {icon
                ? <img className={styles.buttonLeftAddon} src={'../images/icons/' + icon} />
                : undefined}
            <Button
                type={type}
                id={id}
                style={{ 
                    width: width,
                    height: height,
                    backgroundColor: disabled ? disabledColor : backgroundColor,
                    border: disabled ? disabledColor : border,
                 }}
                className={buttonClasses}
                onClick={onClick}
                disabled={disabled}
            > {label}</Button>
        </div>
    )
}