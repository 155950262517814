import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { default as ReactSelect, SingleValue, MultiValue, ClassNamesConfig } from 'react-select'
import { components } from 'react-select'
import { ErrorMessage } from '../../Form/ErrorMessage'

import styles from './BymaSelect.module.scss'

interface SelectOption {
  label: string;
  value: Identifier;
}

const defaultValueMapper = (option: SelectOption | null): any =>
  option ? option.value : undefined

interface SelectProps {
  id: string;
  name: string;
  label: string;
  options: SelectOption[];
  onChange?: (selected: any) => void;
  className?: string;
  multiselect?: boolean;
  menuIsOpen?: boolean;
  useCheckbox?: boolean;
  width?: string;
  height?: string;
  valueMapper?: (option: SelectOption | null) => any;
}

export const BymaSelect = (props: SelectProps) => {
  const {
    id,
    name,
    options,
    label,
    onChange: customOnChange = () => { },
    multiselect = false,
    menuIsOpen = false,
    useCheckbox = true,
    width = 'inherit',
    height = 'inherit',
    valueMapper = defaultValueMapper,
  } = props

  const Option = (props) => {
    return useCheckbox ? (
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{' '}
        <label>{props.label}</label>
      </components.Option>
    ) : (
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    )
  }

  const handleChange = (selected) => {
    customOnChange(selected)
  }

  const { control } = useFormContext()

  const classesNames: ClassNamesConfig = {
    singleValue: (state) => styles.selectFieldInputSingleValue,
    multiValue: (state) => styles.selectFieldInputMultiValue,
    control: (state) =>
      state.isFocused ? styles.selectFieldControlFocus : styles.selectFieldControl,
    menu: (state) => styles.selectFieldMenu,
    menuList: (state) => styles.selectFieldMenuList,
    option: (state) => styles.selectFieldMenuOption
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const errorMessage = fieldState.error?.message

        return (
          <span
            className={styles.selectFormGroup + " d-inline-block"}
            data-toggle="popover"
            data-trigger="focus"
            data-content="Please selecet account(s)"
            data-mdb-perfect-scrollbar="true"
          >
            <div
              className={styles.bymaSelect}
              style={{ width: width, height: height }}
            >
              {label !== undefined ? <label htmlFor={id}>{label}</label> : null}

              <ReactSelect
                className={styles.selectField + " bg-transparent"}
                options={options}
                isMulti={multiselect}
                closeMenuOnSelect={!multiselect}
                hideSelectedOptions={true}
                components={{ Option }}
                onChange={
                  (val) => {
                    const fieldValue = multiselect
                      ? (val as MultiValue<SelectOption>).map((opt) =>
                        valueMapper(opt)
                      )
                      : valueMapper(val as SingleValue<SelectOption>)

                    field.onChange(fieldValue)

                    handleChange(val)
                  }}
                //allowSelectAll={true}
                //value={selected}
                maxMenuHeight={150}
                menuIsOpen={menuIsOpen ? true : undefined}
                classNames={classesNames}
              />
            </div>
            <ErrorMessage error={errorMessage} />
          </span>
        )
      }}
    />
  )
}
