import * as React from 'react'
import {
    useState,
    useCallback,
    useRef,
    ReactNode,
    HtmlHTMLAttributes,
    useContext,
} from 'react'

import PropTypes from 'prop-types'
import { useListContext } from '../../../core'

import { FilterButtonMenuItem } from './FilterButtonMenuItem'
import { FilterContext } from './FilterContext'
import { BymaDropdownButton } from '../../../components/Buttons/BymaDropdownButton'



export interface FilterButtonProps extends HtmlHTMLAttributes<HTMLDivElement> {
    //classes?: ClassesOverride<typeof useStyles>;
    className?: string;
    resource?: string;
    filterValues?: any;
    showFilter?: (filterName: string, defaultValue: any) => void;
    displayedFilters?: any;
    filters?: ReactNode[];
}

const FilterButton = (props: FilterButtonProps): JSX.Element | null => {
    const {
        filters: filtersProp,
        //classes: classesOverride,
        className,
        ...rest
    } = props
    const filters = useContext(FilterContext) || filtersProp

    const { displayedFilters = {}, filterValues, showFilter, hideFilter } = useListContext(
        props
    )
    const [open, setOpen] = useState(false)
    const anchorEl = useRef()

    const handleClickButton = useCallback(
        event => {
            // This prevents ghost click.
            event.preventDefault()
            setOpen(true)
            anchorEl.current = event.currentTarget
        },
        [anchorEl, setOpen]
    )

    const handleRequestClose = useCallback(() => {
        setOpen(false)
    }, [setOpen])

    const handleShow = useCallback(
        ({ source, defaultValue }) => {
            console.log(`DEBUG FilterButton handleShow(${source},${defaultValue})`)
            showFilter(source, defaultValue)
            setOpen(false)
        },
        [showFilter, setOpen]
    )

    const handleHide = useCallback(
        ({ source }) => {
            console.log(`DEBUG FilterButton handleHide(${source})`)
            hideFilter(source)
            setOpen(false)
        },
        [hideFilter, setOpen]
    )

    console.log(`DEBUG FilterButton render displayedFilters= ${JSON.stringify(displayedFilters)}`)

    return (
        <div
            {...sanitizeRestProps(rest)}
        >
            <BymaDropdownButton
                id='filter-button'
                name='filter-button'
                icon='add-filter.svg'
                className={className}
            >
                {(filters || []).map((filter) => {
                    const filterElement = filter as JSX.Element
                    const checked =
                        displayedFilters[filterElement.props.name] !== undefined ?
                            displayedFilters[filterElement.props.name]
                            : false

                    return (
                        <FilterButtonMenuItem
                            key={filterElement.props.name}
                            filter={filterElement}
                            resource=""
                            onShow={handleShow}
                            onHide={handleHide}
                            checked={checked}
                        />)
                })}
            </BymaDropdownButton>

            {/*
            <Dropdown className={styles.bymaDropdownButton}>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Filtros
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {

                        (filters || []).map((filter) => {
                            const filterElement = filter as JSX.Element
                            const checked =
                                displayedFilters[filterElement.props.name] !== undefined ?
                                    displayedFilters[filterElement.props.name]
                                    : false

                            return (
                                <FilterButtonMenuItem
                                    key={filterElement.props.name}
                                    filter={filterElement}
                                    resource=""
                                    onShow={handleShow}
                                    onHide={handleHide}
                                    checked={checked}
                                />
                            )
                        }
                        )
                    }

                </Dropdown.Menu>
            </Dropdown>
            */}
        </div>
    )
}

const sanitizeRestProps = ({
    displayedFilters = null,
    filterValues = null,
    showFilter = (filterName: string, defaultValue: any): void => { },
    ...rest
}) => rest

FilterButton.propTypes = {
    resource: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.node),
    displayedFilters: PropTypes.object,
    filterValues: PropTypes.object,
    showFilter: PropTypes.func,
    classes: PropTypes.object,
    className: PropTypes.string,
}
export default FilterButton
