import { createContext } from 'react'

export const defaultNotificationApiContextInfo = {
    notifications: [],

    addNotification: (notification) => {
        console.log(`DEBUG notification ATENCION!!!: addNotification handler default ${JSON.stringify(notification)}`)
        return 1
     },
    removeNotifications: () => {
        console.log('DEBUG notification ATENCION!!!: removeNotification handler default')
        return 1
    }
}

const NotificationApiContext = createContext<NotificationApiContextInfo | undefined>(undefined)

NotificationApiContext.displayName = 'NotificationApiContext'

const NotificationDataContext = createContext<NotificationDataContextInfo>({notifications: []})

NotificationDataContext.displayName = 'NotificationDataContext'

export {NotificationApiContext , NotificationDataContext}