import React from 'react'

import { BymaButton } from '../BymaButton/BymaButton'
import { useDownloadFile, DownloadFileProps } from '../../../core'

import styles from './BymaExportButton.module.scss'

export type ExportButtonProps = Omit<Partial<DownloadFileProps>,'serverDownload'> & 
{

  id: string
  name: string
  columns?: any[]
  label?: string
  icon?: string,
  serverDownload: () => Promise<Blob>,
  getFileName:  () => string

}

export const BymaExportButton = ({
  id, 
  label = '', 
  icon = 'download.svg',
  serverDownload,
  getFileName,
  onError = (error) => alert(`Error en Export ${error}`),
  preDownloading = () => {},
  postDownloading = () => {
    alert('Export Finalizado')
  }

} : ExportButtonProps) => {

  const { 
    download, 
    ref: exportLinkRef, 
    url: exportLinkUrl,
    name: exportFileName,
    isDownloading
  } = 

  useDownloadFile({
    serverDownload: serverDownload,
    getFileName: getFileName,
    onError: onError,
    preDownloading: preDownloading,
    postDownloading: postDownloading,
  })

  return (
    <div>
      <a href={exportLinkUrl} download={exportFileName} className="hidden" ref={exportLinkRef} />
      <BymaButton
        type="button"
        id={id}
        name={id}
        label={label}
        onClick={download}
        //groupClassName={'export-group-button'}
        groupClassName={styles.exportGroupButton}
        backgroundColor={'#626573'}
        border={'1px solid #626573'}
        icon={icon}
        disabled={isDownloading}
        
    />
    </div>
  )

}
