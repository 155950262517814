import { createContext } from 'react'

const defaultProvider: AuthContextInfo = {
  login: (params: any) => Promise.resolve({success: true}),

  authenticated: false,
  setAuthenticated: () => {},

  token: 'SAMPLETOKEN',
  setToken: () => {},

  userInfo: {username: 'Anonimo', authorities: [], organizacion: 'Sin Organizacicon', passwordExpirationDays: 30},
  setUserInfo: () => {},

  getPasswordExpirationDays: () => undefined
}

const AuthContext = createContext<AuthContextInfo>(defaultProvider)

AuthContext.displayName = 'AuthContext'

export default AuthContext
