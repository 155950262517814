import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { mapDenominableToOption } from '../../utils/model'

import {
  BymaCard,
  BymaDualListBox,
  BymaPageFrame,
  BymaHelpBox,
  BymaExportButton, 
  BymaButton, 
  BymaSelect, 
  Loading 
} from '../../components'
import BymaModal from '../../components/BymaModal/BymaModal'

import RolesApi from './RolesApi'
import PermisosApi from './PermisosApi'

import * as yup from 'yup'

import { Col, Form, Row } from 'react-bootstrap'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQueries, useMutation, useQuery } from 'react-query'

import { useNavigate } from 'react-router-dom'
import { Rol, Permiso } from './Model'

import { useAuthContext, userHasAuthority } from '../../core'
import { useNotificationProducer } from '../../core/notification'

import './Roles.scss'


const RolEdit = () => {

  console.log('DEBUG render RoleEdit')

  const { userInfo } = useAuthContext()
  
  const readOnly = !userHasAuthority(userInfo, 'rol:write')

  const navigate = useNavigate()
  const [roles, setRoles] = useState<Rol[]>([])
  const [selectedRolId, setSelectedRolId] = useState<Identifier | undefined>(undefined)
  const [rol, setRol] = useState<Rol | undefined>(undefined)
  const [permisos, setPermisos] = useState<Permiso[]>([])

  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [formData, setFormData] = useState<any>(undefined)

  const queryResults = useQueries(
    [
      {
        queryKey: ['roles'],
        queryFn: async () => { console.log('DEBUG RolesEdit en query[roles]'); return RolesApi.getList() },
        onSuccess: (data: Rol[]) => {
          setRoles(data)
        },
        refetchOnWindowFocus: false,
      },
      {
        queryKey: ['permisos'],
        queryFn: async () => { console.log('DEBUG RolesEdit en query[permisos]'); return PermisosApi.getList() },
        onSuccess: (data: Permiso[]) => {
          setPermisos(data)
        },
        refetchOnWindowFocus: false,
      },
    ]
  )

  const isLoadingData = queryResults.some(query => query.isLoading)
  const isFetchingData = queryResults.some(query => query.isFetching)
  const isErrorData = queryResults.some(query => query.isError)
  const errorData = queryResults.some(query => query.error)

  //TODO JIRA BP-52 Usar un nuevo hook useBymaQuery que incluya manejo notification y progress
  const { isLoading: isLoadingRol, isFetching: isFetchingRol = false, isError, error: errorRol, refetch } =
    useQuery(
      ['rol', selectedRolId],
      () => { console.log(`DEBUG RolesEdit en queryRol[${selectedRolId}]`); return RolesApi.getOne(selectedRolId) },
      {
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
          setRol(data)
        },
        enabled: rol !== selectedRolId,
      })

  const { addNotification } = useNotificationProducer()

  //TODO JIRA BP-51 Usar un nuevo hook useBymaMutation que incluya manejo notification y progress
  const { mutate } = useMutation(
    (rol: Rol) => RolesApi.update(rol),
    {
      onSuccess: data => {
        addNotification({message: 'Se actualizo el Rol', type: 'info'})
        //hacemos un refectch por las dudas el backend haya hecho modificaciones
        refetch()
      },
      onError: () => {
        addNotification({message: 'Error actualizando Rol', type: 'error'})
      },
      onSettled: () => {
        navigate('/roles')
      }
    })

  const handleCancel = () => {
    //TODO a donde lleva el 'Cancelar' ?
    navigate('/roles')
  }

  const permisosOptions = permisos && rol ? permisos
    .map(mapDenominableToOption)
    : []

  const permisosSelected = rol ? rol.permisos.map(permiso => permiso.id)
    : []

  console.log(`DEBUG RolesEdit PermisosSelected=${JSON.stringify(permisosSelected)}`)

  //FORM
  const schema = () =>
    yup
      .object()
      .shape({
        id: yup.number().required(),
        permisos: yup.array()
          .min(1, 'Informar al menos un permiso')
          .required('Informar al menos un permiso')
      })
      

  const methods = useForm({
    resolver: yupResolver(schema()),
    mode: readOnly  ? 'onSubmit' : 'all',
    reValidateMode: readOnly  ? 'onSubmit' : 'onChange',
    shouldUnregister: false,
    defaultValues: { id: undefined, permisos: []}
  })

  console.log(`DEBUG RolesEdit formstate.isValid = ${methods.formState.isValid} formState.isLoading=${methods.formState.isLoading}`)
  console.log(`DEBUG RolesEdit formstate.errors = ${JSON.stringify(methods.formState.errors)}`)

  React.useEffect(() => {
    methods.setFocus('id')
  }, [methods.setFocus])

  const handleConfirm = () => {
    console.log(`DEBUG RolesEdit onSubmit(${JSON.stringify(formData)})`)

    const dto = {
      id: formData.id,
      permisos: formData.permisos.map(p => ({ id: p }))
    }

    //TODO REVISAR ESTO: en este tipo de formulario tenemos que actualizar el objeto subyacente para que no 'resetee'
    setRol({ id: selectedRolId || 0, permisos: dto.permisos })

    mutate(dto)
  }

  const submitDisabled = Object.keys(methods.formState.errors).length !== 0

  const onSubmit = (data: any) => {
    setFormData(data)
    setShowConfirm(true)
  }


  const editRolForm = () => {
    return (
      <FormProvider {...methods}>
        <Form id="roles-edit-form"
          className="roles-edit-form"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <BymaCard
            height='460px'
            width='90%'
            footer={
              <div>

                {!readOnly &&
                  <BymaButton
                    type="submit"
                    id="roles-form-btn"
                    name="roles-form-btn"
                    label={'Guardar'}
                    backgroundColor={'#626573'}
                    border={'1px solid #626573'}
                    disabled={submitDisabled}
                  />
                }

                <BymaButton
                  type="button"
                  id="roles-form-btn"
                  name="roles-form-btn"
                  label={'Cancelar'}
                  backgroundColor={'transparent'}
                  border={'1px solid #626573'}
                  onClick={handleCancel}
                />
              </div>
            }>
            <Row>
              <Col>
                <BymaSelect
                  id="id"
                  name="id"
                  label="Roles"
                  className="form-input"
                  multiselect={false}
                  menuIsOpen={false}
                  useCheckbox={false}
                  width='90%'
                  onChange={(selected) => {
                    setSelectedRolId(selected.value)
                  }}
                  options={roles.map(mapDenominableToOption)}
                />
              </Col>
              <Col>
                <div className='export-buttom-col'>
                  <BymaExportButton
                    id="roles-export-button"
                    name="roles-export-button"
                    serverDownload={() => RolesApi.export()}
                    getFileName={() => 'roles.csv'}
                    postDownloading={() => addNotification({message: 'Se descargo archivo de Roles', type: 'info'})}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {
                  <Loading
                    isLoading={isFetchingRol || isLoadingRol}
                    loadingText=""
                  >
                    <BymaDualListBox
                      id="permisos"
                      name="permisos"
                      labelAvailable="Permisos"
                      labelSelected="Permisos"
                      options={permisosOptions}
                      selectedOptions={permisosSelected}
                      canFilter={true}
                      readOnly={readOnly}
                      shouldDirty={!readOnly}
                      shouldValidate={!readOnly}
                      height='90%'
                    />
                  </Loading>
                }
              </Col>
            </Row>
          </BymaCard>

          <BymaModal
            show={showConfirm}
            content={<p>Confirma actualizacion de Rol?</p>}
            handleCancel={() => { setShowConfirm(false) }}
            handleConfirm={() => {
              handleConfirm()
              setShowConfirm(false)
            }}
          />

        </Form>
      </FormProvider>
    )
  }

  return (
    <BymaPageFrame
      //toastBody="hola mundo"
      bcHome={{ value: 'Dashboard', link: '/' }}
      bcItems={[]}
      bcActive="Roles"
      title="ADMINISTRAR ROLES"
      iconTitle='login/lock.svg'
      className='roles-edit-container'>
      <Loading
        isLoading={isLoadingData || isFetchingData}
        loadingText="Cargando datos..."
      >
        <div>
          {isErrorData
            ? (<span>{`Error: ${errorData}`}</span>)
            : (
              <div>
                <Row>
                  <Col className="col-3">
                    <BymaHelpBox
                      title="Roles y permisos"
                      children={
                        <p>Seleccioná sobre que rol vas a modificar.
                          Una vez seleccionado mueve de izquierda hacia derecha que permisos quieres
                          otorgar a este rol. Y de derecha a izquierda para quitarle.
                        </p>
                      }
                    />
                  </Col>
                  <Col className="col-9">
                    {editRolForm()}
                  </Col>
                </Row>
              </div>
            )}
        </div>
      </Loading>
    </BymaPageFrame>
  )

}

export default RolEdit

