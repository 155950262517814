import React, { useEffect, useState } from 'react'
import { Route, useNavigate } from 'react-router-dom'
import useAuthContext from '../../core/auth/useAuthContext'

//TODO see https://www.toptal.com/react/react-router-tutorial
const ProtectedRoute = (props: any) => {
  const auth = useAuthContext()
  const navigate = useNavigate()
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const checkAuthenticated = () => {
    if (!auth.authenticated) {
      setIsLoggedIn(false)
      return navigate('/login')
    }
    if(auth.userInfo && auth.userInfo.passwordExpirationDays <= 0){
      setIsLoggedIn(false)
      return navigate('/change-password-expired')
    }
    setIsLoggedIn(true)
  }

  useEffect(() => {
    checkAuthenticated()
  }, [isLoggedIn])

  return <React.Fragment>{isLoggedIn ? props.children : null}</React.Fragment>
}
export default ProtectedRoute
