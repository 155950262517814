import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const colocacionesResponse = [
  {
    id: 24715699,
    fechaAlta: '2021-02-15',
    colocadorDenominacion: 'Colocador 1',
    emisorDenominacion: 'Emisor 1',
    totalEmision: 350000,
  },
  {
    id: 24715700,
    fechaAlta: '2021-02-16',
    colocadorDenominacion: 'Colocador 2',
    emisorDenominacion: 'Emisor 2',
    totalEmision: 450000,
  },
  {
    id: 24715701,
    fechaAlta: '2021-02-17',
    colocadorDenominacion: 'Colocador 3',
    emisorDenominacion: 'Emisor 3',
    totalEmision: 550000,
  },
]

export const colocacionesDashboardResponse = [
  { 
      id: 3120, denominacion: 'Colocacion 3120 ON PYME CNV Garantizada CONTRUIR', estado: 'Abierta',
      titulos: [ 
        { 
          id: 11, 
          denominacion: 'Titulo 1.1', 
          totalEmision: 10000000 , 
          tramoCompetitivo: { cantidadMinima: 100, precioMinimo: 5, precioMaximo: 15},
          tramoNoCompetitivo: { cantidadMinima: 500}
        },  
        { 
          id: 12 ,denominacion: 
          'Titulo 1.2', 
          totalEmision: 50000000,
          tramoCompetitivo: { cantidadMinima: 100, precioMinimo: 5, precioMaximo: 15},
          tramoNoCompetitivo: { cantidadMinima: 500}
        }
      ]
  },
  { 
      id: 3121, denominacion: 'Colocacion 3121 ON PYME CNV Garantizada CONTRUIR', estado: 'Abierta',
      titulos: [ { id: 21, denominacion: 'Titulo 2.1'},  { id: 22, denominacion: 'Titulo 2.2'}]
  },
  { 
      id: 3122, denominacion: 'Colocacion 3122 ON PYME CNV Garantizada CONTRUIR', estado: 'Abierta',
      titulos: [ { id: 31, denominacion: 'Titulo 3.1'},  { id: 32, denominacion: 'Titulo 3.2'}]
  },
  { 
    id: 3123, denominacion: 'Colocacion 3124 ON PYME CNV Garantizada CONTRUIR', estado: 'Abierta',
    titulos: [ 
      { 
        id: 41, 
        denominacion: 'Titulo 4.1', 
        totalEmision: 10000000 , 
        tramoCompetitivo: { cantidadMinima: 100, precioMinimo: 5, precioMaximo: 15},
        tramoNoCompetitivo: { cantidadMinima: 500}
      },  
      { 
        id: 42 ,denominacion: 
        'Titulo 4.2', 
        totalEmision: 50000000,
        tramoCompetitivo: { cantidadMinima: 100, precioMinimo: 5, precioMaximo: 15},
        tramoNoCompetitivo: { cantidadMinima: 500}
      }
    ]
  },
  { 
    id: 3125, denominacion: 'Colocacion 3125 ON PYME CNV Garantizada CONTRUIR', estado: 'Abierta',
    titulos: [ 
      { 
        id: 51, 
        denominacion: 'Titulo 5.1', 
        totalEmision: 10000000 , 
        tramoCompetitivo: { cantidadMinima: 100, precioMinimo: 5, precioMaximo: 15},
        tramoNoCompetitivo: { cantidadMinima: 500}
      },  
      { 
        id: 52 ,denominacion: 
        'Titulo 5.2', 
        totalEmision: 50000000,
        tramoCompetitivo: { cantidadMinima: 100, precioMinimo: 5, precioMaximo: 15},
        tramoNoCompetitivo: { cantidadMinima: 500}
      }
    ]
  },


]


export const getColocacionResponse = (id: number) => {
  const colocaciones = colocacionesResponse.filter(col => col.id == id)
  return colocaciones !== undefined && colocaciones.length > 0
    ? colocaciones[0] : {}
}

export const mockColocaciones = (mock: AxiosMockAdapter) => {

  mock.onGet('/api/colocaciones').reply((config: any) => {
    return [200, colocacionesResponse]
  })

  mock.onGet('/api/colocaciones/dashboard').reply((config: any) => {
    return [200, colocacionesDashboardResponse]
  })

  mock.onGet('/api/colocaciones/:id').reply((config: any) => {
    const { id } = config.routeParams
    return [200, getColocacionResponse(id)]
  })

  mock.onPatch('/api/colocaciones/{id}').reply(() => {
    return [200, getColocacionResponse(1)]
  })
}
