import useAuthContext from './useAuthContext'

export const isComponentAuthorized = (componentPermissions : ComponentPermission[], userPermissions : string[] | undefined)  => {

    const matchPermission = (list: string[], permission: ComponentPermission) => {
        return list.some(up => {
          return up.match(permission)
        })
    }

    const result = 
        !componentPermissions || componentPermissions.length == 0 || 
        (
          userPermissions &&
          componentPermissions.some(permission => matchPermission(userPermissions,permission))
        )
      return result
}
  
export const authorizeComponent = <T,> 
   (component: T, componentPermissions : ComponentPermission[], userPermissions : string[] | undefined) : T | false => 
    isComponentAuthorized(componentPermissions, userPermissions) ? component : false


export const withPermissions = <T,> (component: T, componentPermissions:  ComponentPermission[]) : T | false => {
  const authContext = useAuthContext()
  return authorizeComponent(component, componentPermissions, authContext.userInfo?.authorities)
}

export const isPasswordExpired = () => {
  const authContext = useAuthContext()
  return authContext !== undefined
  && authContext.userInfo !== undefined
  && authContext.userInfo.passwordExpirationDays !== undefined
  && authContext.userInfo.passwordExpirationDays <= 0
  ? true : false;
}

export const userHasAuthority = (user: UserInfo | undefined, authority: string) => user && user.authorities.includes(authority)

  