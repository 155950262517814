import React, { ReactNode } from 'react'
import Accordion from 'react-bootstrap/Accordion';

import styles from './BymaAccordion.module.scss'

interface AccordionItem {
    header: ReactNode
    body: ReactNode
    footer?: ReactNode
}

interface AccordionProps {
    key: string
    items: AccordionItem[]
    className?: string
    defaultActiveKey?: string 
    alwaysOpen?: boolean
}

const BymaAccordion = (props: AccordionProps) => {
    const {
        key,
        className = '',
        defaultActiveKey = undefined,
        items,
        alwaysOpen = true
    } = props
    return (
        <Accordion
            key={key}
            defaultActiveKey={defaultActiveKey}
            className={styles.bymaAccordion}
            alwaysOpen={alwaysOpen}>
            {items && items.length > 0 &&
                items.map((item: any, idx: number) =>
                    <Accordion.Item
                        className={styles.accordionItem}
                        eventKey={idx.toString()}
                        key={'item-' + idx}>
                        <Accordion.Header className={styles.accordionHeader}>
                            {item.header}
                        </Accordion.Header>
                        <Accordion.Body className={styles.accordionBody}>
                            {item.body}
                        </Accordion.Body>
                    </Accordion.Item>)
            }
        </Accordion>
    )
}

export default BymaAccordion