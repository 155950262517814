import * as React from 'react'
import {
    useEffect,
    useCallback,
    useContext,
    HtmlHTMLAttributes,
    ReactNode,
} from 'react'
import PropTypes from 'prop-types'
import { useListContext } from '../../../core'

import { useForm, FormProvider } from 'react-hook-form'

//import { Form, FormRenderProps, FormSpy } from 'react-final-form';
//import arrayMutators from 'final-form-arrays';
//import classnames from 'classnames'
//import { makeStyles } from '@material-ui/core/styles';

import lodashSet from 'lodash/set'
import lodashGet from 'lodash/get'
import lodashUnset from 'lodash/unset'
import cloneDeep from 'lodash/cloneDeep'

import FilterFormInput from './FilterFormInput'

//import { ClassesOverride } from '../../types';

import { FilterContext } from './FilterContext'

const getInputValue = (
    formValues: Record<string, any>,
    key: string,
    filterValues: Record<string, any>
) => {
    if (formValues[key] === undefined || formValues[key] === null) {
        return ''
    }
    if (Array.isArray(formValues[key])) {
        return lodashGet(filterValues, key, '')
    }
    if (formValues[key] instanceof Date) {
        return lodashGet(filterValues, key, '')
    }
    if (typeof formValues[key] === 'object') {
        const inputValues = Object.keys(formValues[key]).reduce(
            (acc, innerKey) => {
                const nestedInputValue = getInputValue(
                    formValues[key],
                    innerKey,
                    (filterValues || {})[key] ?? {}
                )
                if (nestedInputValue === '') {
                    return acc
                }
                acc[innerKey] = nestedInputValue
                return acc
            },
            {}
        )
        if (!Object.keys(inputValues).length) return ''
        return inputValues
    }
    return lodashGet(filterValues, key, '')
}


/**
 * Because we are using controlled inputs with react-hook-form, we must provide a default value
 * for each input when resetting the form. (see https://react-hook-form.com/api/useform/reset).
 * To ensure we don't provide undefined which will result to the current input value being reapplied
 * and due to the dynamic nature of the filter form, we rebuild the filter form values from its current
 * values and make sure to pass at least an empty string for each input.
 */
export const getFilterFormValues = (
    formValues: Record<string, any>,
    filterValues: Record<string, any>
) => {
    return Object.keys(formValues).reduce((acc, key) => {
        acc[key] = getInputValue(formValues, key, filterValues)
        return acc
    }, cloneDeep(filterValues) ?? {})
}


export const FilterForm = (props: FilterFormProps) => {

    const {
        //classes = {},
        className,
        margin,
        filters,
        variant,
        initialValues,
        ...rest
    } = props

    console.log(`DEBUG FilterForm filters=${filters.length}`)

    const { displayedFilters = {}, hideFilter } = useListContext(props)

    useEffect(() => {
        filters.forEach((filter) => {
            const filterElement = filter as JSX.Element
            if (filterElement.props.alwaysOn && filterElement.props.defaultValue) {
                throw new Error(
                    'Cannot use alwaysOn and defaultValue on a filter input. Please set the filterDefaultValues props on the <List> element instead.'
                )
            }
        })
    }, [filters])

    const getShownFilters = () =>
        filters.filter((filter) => {
            const filterElement = filter as JSX.Element
            return filterElement.props.alwaysOn ||
                displayedFilters[filterElement.props.name] ||
                typeof lodashGet(initialValues, filterElement.props.name) !==
                'undefined'
        }
        )

    const handleHide = useCallback(
        event => {
            console.log(`DEBUG FilterForm handleHide event.currentTarget.dataset = ${JSON.stringify(event.currentTarget.dataset)}`)

            hideFilter(event.currentTarget.dataset.key)
        },
        [hideFilter]
    )

    return (
        <form
            //className={classnames(className, classes.form)}
            {...sanitizeRestProps(rest)}
            onSubmit={handleSubmit}
            className={className}
        >
            {getShownFilters().map((filter) => {
                const filterElement = filter as JSX.Element
                return (
                    <FilterFormInput
                        key={filterElement.props.name}
                        filterElement={filterElement}
                        handleHide={handleHide}
                        variant={filterElement.props.variant || variant}
                        margin={filterElement.props.margin || margin}
                    />
                )
            })}
            {/*<div className={classes.clearFix} />*/}
        </form>
    )
}

const handleSubmit = event => {
    event.preventDefault()
    return false
}

FilterForm.propTypes = {
    resource: PropTypes.string,
    filters: PropTypes.arrayOf(PropTypes.node).isRequired,
    displayedFilters: PropTypes.object,
    hideFilter: PropTypes.func,
    initialValues: PropTypes.object,
    classes: PropTypes.object,
    className: PropTypes.string,
}

/*
const useStyles = makeStyles(
    theme => ({
        form: {
            marginTop: -theme.spacing(2),
            paddingTop: 0,
            display: 'flex',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            minHeight: theme.spacing(10),
            pointerEvents: 'none',
        },
        clearFix: { clear: 'right' },
    }),
    { name: 'RaFilterForm' }
);
*/

const sanitizeRestProps = ({
    //active,
    //dirty,
    //dirtyFields,
    //dirtyFieldsSinceLastSubmit,
    //dirtySinceLastSubmit,
    //displayedFilters,
    //error,
    //errors,
    filterValues,
    //form,
    //handleSubmit,
    //hasSubmitErrors,
    //hasValidationErrors,
    hideFilter,
    //invalid,
    //modified,
    //modifiedSinceLastSubmit,
    //pristine,
    //resource,
    setFilters,
    //submitError,
    //submitErrors,
    //submitFailed,
    //submitSucceeded,
    //submitting,
    //touched,
    //valid,
    //validating,
    //values,
    //visited,
    ...props
}: Partial<FilterFormProps>) => props

export interface FilterFormProps
    extends Omit<HtmlHTMLAttributes<HTMLFormElement>, 'children'> {

    filters: ReactNode[];
    //classes?: ClassesOverride<typeof useStyles>;

    className?: string;
    resource?: string;
    filterValues?: any;
    hideFilter?: (filterName: string) => void;
    setFilters?: (filters: any, displayedFilters: any) => void;
    displayedFilters?: any;
    initialValues?: any;
    margin?: 'none' | 'normal' | 'dense';
    variant?: 'standard' | 'outlined' | 'filled';
}

export const mergeInitialValuesWithDefaultValues = (
    initialValues,
    filters
) => ({
    ...filters
        .filter(
            (filterElement: JSX.Element) =>
                filterElement.props.alwaysOn && filterElement.props.defaultValue
        )
        .reduce(
            (acc, filterElement: JSX.Element) =>
                lodashSet(
                    { ...acc },
                    filterElement.props.source,
                    filterElement.props.defaultValue
                ),
            {} as any
        ),
    ...initialValues,
})

const EnhancedFilterForm = (props: FilterFormProps) => {
    const {
        //classes: classesOverride,
        filters: filtersProps,
        initialValues,
        className = '',
        ...rest
    } = props
    //const classes = useStyles(props);
    const { setFilters, displayedFilters, filterValues } = useListContext(
        props
    )

    console.log(`EnhancedFilterForm filterProps=${filtersProps.length}`)

    const filters = useContext(FilterContext) || filtersProps

    const mergedInitialValuesWithDefaultValues = mergeInitialValuesWithDefaultValues(
        initialValues || filterValues,
        filters
    )

    // https://react-hook-form.com/api/useform/watch/
    // https://www.google.com.ar/search?q=react-hook-form+watch+changes&sxsrf=APwXEdd-meIzY6ct33r8UeFNtT5lxdqVow%3A1680002056524&ei=CMwiZNXHH5Xc1sQPi_6uKA&ved=0ahUKEwjV67Ouv_79AhUVrpUCHQu_CwUQ4dUDCA8&uact=5&oq=react-hook-form+watch+changes&gs_lcp=Cgxnd3Mtd2l6LXNlcnAQAzIGCAAQFhAeMggIABCKBRCGAzIICAAQigUQhgMyCAgAEIoFEIYDMggIABCKBRCGAzIICAAQigUQhgM6CggAEEcQ1gQQsAM6CggAEIoFELADEEM6BQgAEIAESgQIQRgAULkkWNYvYIozaAFwAXgAgAFmiAHWBZIBAzcuMZgBAKABAcgBCsABAQ&sclient=gws-wiz-serp#fpstate=ive&vld=cid:85a964a0,vid:3qLd69WMqKk
    const form = useForm({
        defaultValues: mergedInitialValuesWithDefaultValues
    })
    const { watch, handleSubmit } = form

    useEffect(() => {
        const newValues = getFilterFormValues(form.getValues(), filterValues)
        form.reset(newValues)
    }, [filterValues, form])


    useEffect(() => {
        const subscription = watch(async (values, { name, type }) => {
            // We must check whether the form is valid as watch will not check that for us.
            // We can't rely on form state as it might not be synchronized yet
            const isFormValid = await form.trigger()

            if (isFormValid && name) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                if (lodashGet(values, name!) === '') {
                    const newValues = cloneDeep(values)
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    lodashUnset(newValues, name!)
                    setFilters(newValues, displayedFilters)
                } else {
                    setFilters(values, displayedFilters)
                }
            }
        })
        return () => subscription.unsubscribe()
    }, [displayedFilters, form, setFilters])

    const onSubmit = () => { }

    return (
        <FormProvider {...form}>
            <form onSubmit={handleSubmit(onSubmit)}>

                {/*mutators={{ ...arrayMutators }} */}

                <FilterForm
                    className={className}
                    //classes={classes}
                    //{...formProps}
                    {...rest}
                    filters={filters}
                />
            </form>
        </FormProvider>
    )
}



export default EnhancedFilterForm
