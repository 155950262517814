import useAuthContext from '../../core/auth/useAuthContext'
import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Loading } from '../../components'

import { authenticationService, AuthenticationServiceResponse, AuthenticationError } from '../../services/auth/authenticationService'
//TODO incluir todos los componentes de form en index
import { TextInput } from '../../components/Inputs/TextInput/TextInput'

import * as yup from 'yup'

import { Container, Form, FormGroup } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import styles from './Login.module.scss'
import { BymaButton } from '../../components/Buttons/BymaButton'
import BymaAlert from '../../components/BymaAlert/BymaAlert'

import { PASSWORD_EXPIRATION_DAYS_WARNING } from '../../config'

type FormValues = {
  username: string
  password: string
}

const schema = () =>
  yup
    .object()
    .shape({
      username: yup.string().required(),
      password: yup.string().required(),
    })
    .required()

const Login = () => {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [showRecoveryToast, setShowRecoveryToast] = useState<boolean>(false)
  const authContext: AuthContextInfo = useAuthContext()

  const useFormResult = useForm<FormValues>({
    resolver: yupResolver(schema()),
    mode: 'all',
    reValidateMode: 'onChange',
  })

  const { handleSubmit, setFocus } = useFormResult

  React.useEffect(() => {
    setFocus('username')
  }, [setFocus])

  const translateAuthenticationError = (response: AuthenticationServiceResponse) => {
    return response.errorCode === AuthenticationError.INVALID_CREDENTIALS ?
      'Credenciales inválidas: Usuario y/o contraseña incorrecta' :
      response.error ? response.error : 'Error inesperado'
  }

  const onSubmit = handleSubmit((data) => {
    setLoading(true)
    authenticationService
      .login({ username: data.username, password: data.password })
      .then((response) => {
        setLoading(false)
        if (!response.success) {
          console.log(`DEBUG Login error = ${response.error} response = ${JSON.stringify(response)}`)
          setError(translateAuthenticationError(response))
          return
        }

        authContext.setToken(response.token)
        authContext.setAuthenticated(true)
        authContext.setUserInfo(response.userInfo)

        let navigateTo = '/home'

        if (response.userInfo &&
          response.userInfo.passwordExpirationDays > 0 &&
          response.userInfo.passwordExpirationDays <= PASSWORD_EXPIRATION_DAYS_WARNING) {
          navigateTo = '/change-password-warning'
        }

        setTimeout(() => {
          navigate(navigateTo)
        }, 500)
      })
      .catch((error) => {
        setLoading(false)
        setError(`Error inesperado: ${error}`)
      })
  })

  return (
    <React.Fragment>
      <Container className={styles.login}>
        <Loading isLoading={isLoading}>

          <div className={styles.logo}>
            <img
              alt=""
              className={styles.logoImage}
              src="images/logos/logo-login.svg"
            />

          </div>

          <FormProvider {...useFormResult}>
            <Form id="loginForm" onSubmit={onSubmit} className={styles.form} autoComplete="off">

              <div className={styles.loginHeader}>
                <h2 className={styles.title}>Bienvenido</h2>
                <h5 className={styles.subtitle}>Ingresá tu usuario y contraseña</h5>
              </div>

              <FormGroup>
                <TextInput
                  name="username"
                  id="username"
                  label="Usuario"
                  className={styles.formInput}
                  //icon="fa-thin fa-user"
                  icon="login/person_filled.svg"
                  placeHolder="Nombre de usuario"
                  width='345px'
                ></TextInput>
              </FormGroup>
              <FormGroup>
                <TextInput
                  name="password"
                  id="password"
                  label="Contraseña"
                  type="password"
                  //icon="fa-lock"
                  icon="login/lock.svg"
                  placeHolder="Mínimo 8 caracteres"
                  className={styles.formInput}
                  width='345px'
                ></TextInput>
              </FormGroup>

              <div className={styles.loginErrorAlert}>
                {error &&
                  <BymaAlert
                    variant="danger"
                    children={<p>{error}</p>}
                    onClose={() => { setError(undefined) }} />}
              </div>

              <BymaButton
                type="submit"
                id="login-btn"
                name="login-btn"
                label={'Ingresar'}
                width="345px"
                className={styles.submitButton}
                groupClassName={styles.submitButtonGroup}
              />

              <a
                className={styles.recoveryQuestion}
                onClick={() => {
                  console.log('CLico en open recovery password')
                  setShowRecoveryToast(true)
                }}
              >¿Te olvidaste la contraseña?</a>

              <div className={styles.loginRecoveryAlert}>
                {showRecoveryToast &&
                  <BymaAlert
                    children={
                      <p>
                        La podes restaurar desde la aplicación <a
                          href='https://www.google.com.ar'
                          rel="noopener noreferrer"
                          target="_blank">USUARIOS BYMA</a> o a través
                        de <a href="https://www.w3schools.com/"
                          rel="noopener noreferrer"
                          target="_blank">BYMA DIGITAL</a>.
                      </p>
                    }
                    closable={true}
                    onClose={() => {
                      console.log('CLico en close recovery password')
                      setShowRecoveryToast(false)
                    }}
                    variant="primary"
                  />
                }
              </div>

            </Form>
          </FormProvider>

        </Loading>
      </Container>
    </React.Fragment>
  )
}

export default Login