import React from 'react'
import { useNavigate } from 'react-router-dom'
import { interpolateString } from '../../../utils/string'

import { ButtonProps, BymaButton } from '../BymaButton/BymaButton'

import styles from './BymaNavigateButton.module.scss'

interface NavigateButtonProps extends ButtonProps {
  navigateTo: any
  data: any
  icon?: string
}

export const BymaNavigateButton = (props: any) => {
  const { id, label, navigateTo, data, icon } = props
  const navigate = useNavigate()

  const handleOnClick = () => {
    navigate(interpolateString(navigateTo as string, data))
  }

  return (
    <BymaButton
      type="button"
      id={id}
      name={id}
      label={label}
      onClick={handleOnClick}
      groupClassName={styles.navigateGroupButton}
      backgroundColor={"transparent"}
      border={"none"}
      icon={icon}
    />
  )
}
