import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './ErrorMessage.module.scss'

export interface ErrorMessageProps {
  error?: ValidationError | string
}

export const ErrorMessage = ({ error }: ErrorMessageProps) => {
  const { t } = useTranslation()

  let errorText
  if (error === undefined) {
    errorText = ''
  } else if (typeof error === 'string') {
    errorText = error
  } else {
    errorText = t(error.key, error.values)
  }

  return <div className={styles.errorText}>{errorText}</div>
}
