import React, { useState } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import { AgGridReact } from 'ag-grid-react'

import './BymaTable.scss'

import { Container } from 'react-bootstrap'
import { Loading } from '../Loading'
import { useListContext } from '../../core'
import { BymaNavigateButton } from '../Buttons/BymaNavigateButton'

type TableProps = {
    key: string
    rowData: BaseEntity[] | undefined
    columns: SbaTableColumn[]
    columnDefaults?: BymaTableColumnDefaults
    pagination?: boolean
    pageSize?: number
    isLoading: boolean
    isFetching: boolean
    isError: boolean
    error: string | null | unknown
    alwaysMultiSort?: boolean
}

type ColumnFilterParams = {
    buttons: string[]
    debounceMs: number
}

type BymaTableColumnDefaults = {
    resizable?: boolean
    sortable?: boolean
    filterParams?: ColumnFilterParams
}

type SbaTableColumn = {
    field: string
    comparator?: any
    filter?: any
    cellRenderer?: any
    cellRendererParams?: any

    //sortingOrder es una lista de 'asc' | 'desc' | null para controlar como se modifica el sort segun los click
    //sortingOrder?: any
    //sort?: any
}

const applyListColumnOrder = (columns: SbaTableColumn[],columnOrder: ColumnOrderPayload): SbaTableColumn[] => {
    if(!columnOrder.columnOrder){
        return columns
    }

    const findColumn= (id: string) => columns.find(c => c.field === id)
    
    const ids: string[] = columnOrder.columnOrder

    return ids
        .map(id => findColumn(id))
        .filter(c => c !== undefined)
        .map(c => c as SbaTableColumn)
}
// para delegar el ordenamiento en el server se anulan los comparadores
const prepareServerSideModel = (columnDefaults, columns: SbaTableColumn[], sort: SortPayload) => {
    columnDefaults.comparator = () => 0
   
    /*
    const sortColumn = columns.find( c => c.field === sort.field)
    // See https://www.ag-grid.com/javascript-data-grid/column-state/
    if(sortColumn){
        sortColumn.sort= sort.order ? sort.order.toLowerCase : 'asc'
    }
    */
}

const BymaTable = (props: TableProps) => {
    const columnDefaults = {
        resizable: true,
        sortable: true,
        ...props.columnDefaults,
        filterParams: {
            buttons: ['reset', 'apply'],
            debounceMs: 200,
            ...props.columnDefaults?.filterParams
        },
    }

    const [columns] = useState(props.columns)

    const pagination = props.pagination ? props.pagination : true
    const pageSize = props.pageSize ? props.pageSize : 10

    const {serverSideModel, setSort, sort, columnOrder, setColumnOrder} = useListContext()

    const gridColumns = serverSideModel ? cloneDeep(columns) : columns
    if(serverSideModel){
        prepareServerSideModel(columnDefaults, gridColumns, sort)
    }

    const orderedGridColumns = applyListColumnOrder(gridColumns, columnOrder)

    const onSortChanged = (event) => { 
        const { api: { sortController } } = event
        const sortModel = sortController.getSortModel()

        console.log(`DEBUG BymaTable onSortChanged sortModel=${JSON.stringify(sortModel)}`)

        if (sortModel.length > 0) { 
          const { colId, sort } = sortModel[0]
          setSort( { field: colId, order: sort } )
        } else { 
          //TODO como anular el ordenamiento ? Usar el default ?
          setSort( { field: 'id', order: 'ASC' })
        } 
    }

    const onDragStopped = (params) => {
        const colIds = params.columnApi.getAllDisplayedColumns().map(col => col.colId)
        setColumnOrder({columnOrder: colIds})
      }

    return (
        <Container key={props.key + '-container'} className="table-container">
            <Loading
                isLoading={props.isLoading || props.isFetching}
                loadingText="Cargando datos..."
            >
                <div className="ag-theme-alpine-dark byma-table">
                    {props.isError
                        ? (<span>{`Error: ${props.error}`}</span>)
                        : (<AgGridReact
                            key={props.key + '-table'}
                            rowData={props.rowData}
                            columnDefs={orderedGridColumns}
                            defaultColDef={columnDefaults}
                            pagination={pagination}
                            paginationPageSize={pageSize}
                            alwaysMultiSort={props.alwaysMultiSort}
                            onSortChanged={onSortChanged}
                            onDragStopped={onDragStopped}
                        />)
                    }
                </div>
            </Loading>
        </Container>
    )
}

export default BymaTable

const editCellRendererSelector = ({editPath , editLabel = 'Editar', icon = 'fa fa-edit'}) => (params) => {
    return {
        component: BymaNavigateButton,
        params: {
          navigateTo: `${editPath}/${params.data.id}`,
          label: editLabel,
          icon: icon
        }
    }
}


export {editCellRendererSelector}
