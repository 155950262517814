export default {
    availableFilterHeader: "Filter available",
    availableHeader: "Available",
    filterPlaceholder: "Search...",
    moveAllLeft: "Move all to available",
    moveAllRight: "Move all to selected",
    moveLeft: "Move to available",
    moveRight: "Move to selected",
    moveLeftTooltip: "Move to available",
    moveRightTooltip: "Move to selected",
    moveBottom: "Rearrange to bottom",
    moveDown: "Rearrange down",
    moveUp: "Rearrange up",
    moveTop: "Rearrange to top",
    noAvailableOptions: "No available options",
    noSelectedOptions: "No selected options",
    requiredError: "Please select at least one option.",
    selectedFilterHeader: "Filter selected",
    selectedHeader: "Selected",
};
