import React from 'react'

import Colocaciones from './Colocaciones'

import './Dashboard.scss'

const DashboardAgente = () => {

    return (
        <div className="container">
            <div className="top-container">
                <div className="panel">
                    <ul>
                        <li>Notificacion 1</li>
                        <li>Notificacion 2</li>
                        <li>Notificacion 3</li>
                    </ul>
                </div>
                <div className="panel">Mis Ofertas</div>
            </div>
            <div className="bottom-container"><Colocaciones/></div>
        </div>
    )    
}

export default DashboardAgente