import { ResourceApi } from "../../services/api/resourceApi";
import { ChangePasswordModel } from "./Model";

class UsersApi extends ResourceApi<ChangePasswordModel> {
  constructor() {
    super("/api/users");
  }
}

export default new UsersApi();
