import React , {useMemo, useReducer} from 'react'
import { NotificationApiContext, NotificationDataContext } from './NotificationContext'


const removeNotifications = (state: NotificationDataContextInfo, durationMs: number) => {
  const now = Date.now()

  const newNotifications = state.notifications.filter(n => n.createdAt + durationMs > now)
  if(newNotifications.length !== state.notifications.length){
       return { notifications: newNotifications}
  }else{
    return state
  }
}

const addNotification = (state: NotificationDataContextInfo,notification: NotificationProps ) => {
  const newNotifications = state.notifications.slice()
  newNotifications.push({...notification, createdAt: Date.now()})
  return { notifications: newNotifications}
}

type Actions =
  | { type: 'add'; notification: NotificationProps }
  | { type: 'remove'; durationMs: number }


const reducer = (state: NotificationDataContextInfo, action: Actions): NotificationDataContextInfo => {
  switch (action.type) {
    case 'add':
      return addNotification(state, action.notification)
    case 'remove':
      return removeNotifications(state, action.durationMs)
  }
}

export const NotificationProvider = ( {children} ) => {

  const [notifications, dispatch] = useReducer(
    reducer,
    {notifications: []}
  )

  const api = useMemo(() => (
    {
      removeNotifications: (durationMs: number) => {
        dispatch({type: 'remove', durationMs: durationMs})
      },

      addNotification: (notification: NotificationProps) => {
        dispatch({type: 'add', notification: notification})
      }
    }
  ),[dispatch])
  

  return (
      <NotificationApiContext.Provider 
        value = {api} >
          <NotificationDataContext.Provider
            value={notifications}>
            {children}
        </NotificationDataContext.Provider>
      </NotificationApiContext.Provider>
    )
  }
  
  export default NotificationProvider

