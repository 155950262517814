import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Loading } from '../../components'

//TODO incluir todos los componentes de form en index
import { TextInput } from '../../components/Inputs/TextInput/TextInput'
import ColocacionesApi from './ColocacionesApi'

import * as yup from 'yup'

import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'
import { BymaButton } from '../../components/Buttons/BymaButton'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import { useMutation, useQuery } from 'react-query'

import { Colocacion } from './Model'

import './Colocaciones.scss'

type FormValues = {
  id: number
  fechaAlta: string
  colocadorDenominacion: string
  emisorDenominacion: string
  totalEmision: number
}

const schema = () =>
  yup
    .object()
    .shape({
      id: yup.string().required(),
      fechaAlta: yup.string().required(),
      colocadorDenominacion: yup.string().required(),
      emisorDenominacion: yup.string().required(),
      totalEmision: yup.number().required(),
    })
    .required()

const ColocacionEdit = () => {
  const navigate = useNavigate()

  const id = 1
  const colocacion: Colocacion = {
    id: 5,
    fechaAlta: '2021-02-15',
    colocadorDenominacion: 'Colocador 1',
    emisorDenominacion: 'Emisor 1',
    totalEmision: 25,
  }
  //const [isLoading, setLoading] = useState<boolean>(false)


  const { isLoading, isFetching, isError, data, error } =
    useQuery(['colocacion', id], () => ColocacionesApi.getOne(id), {
      //enabled: false,
      staleTime: Infinity
    })


  const methods = useForm<Colocacion>({
    resolver: yupResolver(schema()),
    mode: 'all',
    reValidateMode: 'onChange',
    //mode: 'onChange'
  })

  const { mutate } = useMutation(
    () => ColocacionesApi.update(colocacion),
    {
      onSuccess: data => {
        console.log(data)
        const message = 'success'
        alert(message)
      },
      onError: () => {
        alert('there was an error')
      },
      onSettled: () => {
        navigate('/colocaciones')
      }
    })

  const onSubmit = (data: Colocacion) => {
    console.log('SUBMITEANDO!!!!')
    console.log(data)
    const colocacion = {
      ...data
    }
    mutate()
  }

  const handleCancel = () => {
    console.log('CANCELOOOOO!!!!')
    navigate('/colocaciones')
  }

  console.log('Rendering colocaciones edit')
  console.log(data)

  return (
    <React.Fragment>
      <Container className="colocaciones-edit-container" >
        <Loading
          isLoading={isLoading || isFetching}
          loadingText="Cargando datos..."
        >
          <div >
            {isError ? (
              <span>{`Error: ${error}`}</span>
            ) : (
              <div>
                <FormProvider {...methods}>
                  <Form id="colocaciones-edit-form" onSubmit={methods.handleSubmit(onSubmit)}>
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <TextInput
                            name="id"
                            id="id"
                            label="Id"
                            value={data?.id}
                          ></TextInput>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup className="mb-3">
                          <TextInput
                            type="date"
                            name="fechaAlta"
                            id="fechaAlta"
                            label="Fecha Alta"
                            value={data?.fechaAlta}
                          ></TextInput>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <TextInput
                            name="colocadorDenominacion"
                            id="colocadorDenominacion"
                            label="Colocador"
                            value={data?.colocadorDenominacion}
                          ></TextInput>
                        </FormGroup>
                      </Col>
                      <Col>
                      <FormGroup className="mb-3">
                          <TextInput
                            name="emisorDenominacion"
                            id="emisorDenominacion"
                            label="Emisor"
                            value={data?.emisorDenominacion}
                          ></TextInput>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-3">
                          <TextInput
                            type="number"
                            name="totalEmision"
                            id="totalEmision"
                            label="Total Emision"
                            value={data?.totalEmision}
                          ></TextInput>
                        </FormGroup>
                      </Col>
                      <Col>
                      </Col>
                    </Row>
                    
                    <div className="form-action-buttons">
                      <BymaButton
                        type="button"
                        id="colocacion-cancel-btn"
                        name="colocacion-cancel-btn"
                        label={'Volver'}
                        onClick={handleCancel}
                      />
                      <BymaButton
                        type="submit"
                        id="colocacion-form-btn"
                        name="colocacion-form-btn"
                        label={'Grabar'}
                      />
                    </div>
                  </Form>
                </FormProvider>
              </div>
            )}
          </div>
        </Loading>
      </Container>
    </React.Fragment>
  )
}

export default ColocacionEdit
