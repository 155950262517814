import React, { useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { Loading } from '../../components'

//TODO incluir todos los componentes de form en index
import { TextInput } from '../../components/Inputs/TextInput/TextInput'
import { BymaButton } from '../../components/Buttons/BymaButton'
import AgentesApi from './AgentesApi'

import * as yup from 'yup'

import { Button, Col, Container, Form, FormGroup, Row } from 'react-bootstrap'

import { yupResolver } from '@hookform/resolvers/yup'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

type FormValues = {
  name: string;
  surname: string;
  birthDate: string;
  agNumber: number;
}

const schema = () =>
  yup
    .object()
    .shape({
      name: yup.string().required(),
      surname: yup.string().required(),
      birthDate: yup.string(),
      agNumber: yup.number(),
    })
    .required()

const AgenteEdit = () => {
  const navigate = useNavigate()

  //TODO tomar el id de la ruta
  const id = 1

  const useFormResult = useForm<FormValues>({
    resolver: yupResolver(schema()),
    mode: 'all',
    reValidateMode: 'onChange'
  })

  const handleCancel = () => {
    console.log('CANCELOOOOO!!!!')
    navigate('/agentes')
  }

  const { handleSubmit, setFocus, reset } = useFormResult

  const { isLoading, isFetching, isError, error } =
    useQuery(['agente', id], () => AgentesApi.getOne(id),
      { refetchOnWindowFocus: false, onSuccess: (data) => { reset(data) } })

  React.useEffect(() => {
    setFocus('name')
  }, [setFocus])

  const onSubmit = handleSubmit((data) => {
    /*setLoading(true)
    auth
      .authenticate(data.username, data.password)
      .then((response) => {
        setLoading(false)
        if (!response.success) {
          alert(`Error de autenticacion ${response.error}`)
          return
        }
        setTimeout(() => {
          navigate('/')
        }, 500)
      })
      .catch((error) => {
        setLoading(false)
        alert(`Oops! Some error occured. ${error}`)
      })*/
  })

  return (
    <React.Fragment>
      <Container className="agentes-edit-container">
        <Loading
          isLoading={isLoading || isFetching}
          loadingText="Cargando datos..."
        >
          <div
            className="agentes-edit-form"
            style={{ height: 600, width: '100%' }}
          >
            {isError ? (
              <span>{`Error: ${error}`}</span>
            ) : (
              <FormProvider {...useFormResult}>
                <Form id="agente-form" onSubmit={onSubmit}>
                  <Row>
                    <Col>
                      <FormGroup className="mb-3">
                        <TextInput
                          name="name"
                          id="name"
                          label="Nombre"
                        ></TextInput>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3">
                        <TextInput
                          name="surname"
                          id="surname"
                          label="Apellido"
                        ></TextInput>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-3">
                        <TextInput
                          name="birthDate"
                          id="birthDate"
                          label="Fecha de nacimiento"
                        ></TextInput>
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3">
                        <TextInput
                          type="number"
                          name="agNumber"
                          id="agNumber"
                          label="Número de agente"
                        ></TextInput>
                      </FormGroup>
                    </Col>
                  </Row>

                  <div className="form-action-buttons">
                    <BymaButton
                      type="button"
                      id="colocacion-cancel-btn"
                      name="colocacion-cancel-btn"
                      label={'Volver'}
                      onClick={handleCancel}
                    />
                    <BymaButton
                      type="submit"
                      id="colocacion-form-btn"
                      name="colocacion-form-btn"
                      label={'Grabar'}
                    />
                  </div>
                </Form>
              </FormProvider>
            )}
          </div>
        </Loading>
      </Container>
    </React.Fragment>
  )
}

export default AgenteEdit
