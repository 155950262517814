import AxiosMockAdapter from 'axios-mock-adapter-path-params'

export const permisosResponse = [
  {
     'id': 3,
     'codigo': 'colocacion:list:colocador',
     'denominacion': 'Listar Colocaciones del Colocador o Co-Colocador'
  },
  {
     'id': 5,
     'codigo': 'rol:list',
     'denominacion': 'Listar Roles'
  },
  {
     'id': 1,
     'codigo': 'colocacion:list:all',
     'denominacion': 'Listar todas las Colocaciones'
  },
  {
     'id': 4,
     'codigo': 'colocacion:list:ingresada',
     'denominacion': 'Listar todas las Colocaciones Ingresadas'
  },
  {
     'id': 6,
     'codigo': 'rol:write',
     'denominacion': 'Modificar Roles'
  },
  {
   'id': 7,
   'codigo': 'oferta:create:propia',
   'denominacion': 'Crear Ofertas Propias'
  },
  {
   'id': 8,
   'codigo': 'oferta:create:en_nombre_de',
   'denominacion': 'Crear Ofertas en nombre de un Agente'
  },
  {
   'id': 9,
   'codigo': 'oferta:create:underwriter',
   'denominacion': 'Crear Ofertas Underwriter'
  }



]

export const getPermisoResponse = (id: number) => {
  const permisos = permisosResponse.filter((col) => col.id == id)
  return permisos !== undefined && permisos.length > 0 ? permisos[0] : {}
}

export const mockPermisos = (mock: AxiosMockAdapter) => {

  mock.onGet('/api/permisos').reply(() => {
    return [200, permisosResponse]
  })
 
}