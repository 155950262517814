import React, { useEffect, useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'

import './BymaBreadcrumb.scss'

interface BreadcrumbProps {
    home: BCLink
    items: BCLink[]
    active: string
}

const BymaBreadcrumb = (props: BreadcrumbProps) => {
    const {
        home,
        items,
        active
    } = props

    return (
        <Breadcrumb className="byma-breadcrumb">
            <Breadcrumb.Item href={home.link} key="bc-home">{home.value}</Breadcrumb.Item>
            {
                items
                && items.length > 0
                && items.map((item: BCLink, idx: number) => (
                    <Breadcrumb.Item href={home.link} key={"bc-" + idx}>
                        {item.value}
                    </Breadcrumb.Item>
                ))
            }
            <Breadcrumb.Item key="bc-active" active>{active}</Breadcrumb.Item>
        </Breadcrumb>
    )
}

export default BymaBreadcrumb