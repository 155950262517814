import React, { ReactNode } from 'react'
import Card from 'react-bootstrap/Card'

import './BymaHelpBox.scss'

interface HelpBoxProps {
    title: string
    children: ReactNode
    className?: string
}

const BymaHelpBox = (props: HelpBoxProps) => {
    const { title, children, className = '' } = props
    return (
        <Card className={"byma-help-box " + className}>
            <Card.Header>{title}</Card.Header>
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    )
}

export default BymaHelpBox